import React, { useMemo } from 'react';

import { Control, Divider } from '@vlabs/uikit';
import { subDays } from 'date-fns';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectDescriptorVersionOptions, selectLISAvailability, selectServiceAvailability } from 'features/info/selectors';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import * as validate from 'forms/validators';

import { eventDescriptorsInitialValues, eventsInitialValues, faceDescriptorsInitialValues, facesInitialValues } from './gcInitialValues';

function GarbageCollectionTargetSubFormComponent({
  prefix,
  LISAvailability,
  isLunaEventsAvailable,
  descriptorVersions,
}) {
  const { control, formState: { errors }, register, watch, setValue } = useFormContext();
  const { t } = useTranslation();

  const buildPrefix = (name) => (prefix ? `${prefix}.${name}` : name);
  const targetValue = watch(buildPrefix('content.target'))?.value;

  const availableTargetOptions = useMemo(() => {
    if (!isLunaEventsAvailable) return LUNA_TASK_ENUMS.TARGET.raw.filter(({ value }) => !['events', 'event_descriptors'].includes(value));
    return LUNA_TASK_ENUMS.TARGET.raw;
  }, [isLunaEventsAvailable]);

  return (
    <>
      <label htmlFor="content.target">
        {t('tasks:GCTask.form.content.target.label')}
        <Control.Select
          id={buildPrefix('content.target')}
          name={buildPrefix('content.target')}
          control={control}
          options={availableTargetOptions}
          onChange={({ value }) => {
            if (value === 'events') setValue('content', eventsInitialValues(LISAvailability));
            if (value === 'event_descriptors') setValue('content', eventDescriptorsInitialValues(descriptorVersions));
            if (value === 'face_descriptors') setValue('content', faceDescriptorsInitialValues(descriptorVersions));
            if (value === 'faces') setValue('content', facesInitialValues(LISAvailability));
          }}
        />
      </label>
      <Divider small />

      <Control.Checkbox
        id={buildPrefix('content.store_results')}
        {...register(buildPrefix('content.store_results'))}
        label={t('tasks:GCTask.form.content.store_results.label')}
        errors={errors}
        disabled={!LISAvailability.taskResults}
      />
      <Divider small />

      {targetValue === 'events' && (
        <>
          <Control.Checkbox
            id={buildPrefix('content.remove_samples')}
            {...register(buildPrefix('content.remove_samples'))}
            label={t('tasks:GCTask.form.content.remove_samples.label')}
            errors={errors}
            disabled={!(LISAvailability.facesSamples && LISAvailability.bodiesSamples)}
          />
          <Divider small />

          <Control.Checkbox
            id={buildPrefix('content.remove_image_origins')}
            {...register(buildPrefix('content.remove_image_origins'))}
            label={t('tasks:GCTask.form.content.remove_image_origins.label')}
            errors={errors}
          />
          <Divider small />

          <label htmlFor="content.filters.account_id">
            {t('tasks:GCTask.form.content.filters.account_id.label')}
            <Control.Input
              id={buildPrefix('content.filters.account_id')}
              {...register(buildPrefix('content.filters.account_id'), {
                validate: validate.UUIDValidator(),
              })}
              placeholder={t('tasks:GCTask.form.content.filters.account_id.placeholder')}
              errors={errors}
            />
          </label>
          <Divider small />

          <Control.DateInput
            id={buildPrefix('content.filters.create_time__lt')}
            name={buildPrefix('content.filters.create_time__lt')}
            label={t('tasks:GCTask.form.content.filters.create_time__lt.label')}
            control={control}
            data-testid="create_time__lt"
            enableTime
            defaultValue={[subDays(new Date(), 1)]}
          />
        </>
      )}

      {targetValue === 'event_descriptors' && (
        <>
          <Control.Select
            id={buildPrefix('content.filters.descriptor_type')}
            name={buildPrefix('content.filters.descriptor_type')}
            label={t('tasks:GCTask.form.content.filters.descriptor_type.label')}
            control={control}
            options={LUNA_TASK_ENUMS.DESCRIPTOR_TYPE.raw}
            rules={{
              required: validate.requiredValidator(),
            }}
            errors={errors}
          />
          <Divider small />

          <Control.Select
            id={buildPrefix('content.filters.descriptor_version')}
            name={buildPrefix('content.filters.descriptor_version')}
            label={t('tasks:GCTask.form.content.filters.descriptor_version.label')}
            placeholder={t('tasks:GCTask.form.content.filters.descriptor_version.placeholder')}
            control={control}
            options={descriptorVersions}
            rules={{
              required: validate.requiredValidator(),
              validate: ({ value }) => validate.intValidator()(value),
            }}
            errors={errors}
            isCreatable
            isClearable
          />
        </>
      )}

      {targetValue === 'faces' && (
        <>
          <Control.Checkbox
            id={buildPrefix('content.remove_samples')}
            {...register(buildPrefix('content.remove_samples'))}
            label={t('tasks:GCTask.form.content.remove_samples.label')}
            errors={errors}
            disabled={!LISAvailability.facesSamples}
          />
          <Divider small />

          <Control.Input
            id={buildPrefix('content.filters.account_id')}
            {...register(buildPrefix('content.filters.account_id'), {
              validate: validate.UUIDValidator(),
            })}
            label={t('tasks:GCTask.form.content.filters.account_id.label')}
            placeholder={t('tasks:GCTask.form.content.filters.account_id.placeholder')}
            errors={errors}
          />
          <Divider small />

          <Control.Input
            id={buildPrefix('content.filters.list_id')}
            {...register(buildPrefix('content.filters.list_id'), {
              validate: validate.UUIDValidator(),
            })}
            label={t('tasks:GCTask.form.content.filters.list_id.label')}
            placeholder={t('tasks:GCTask.form.content.filters.list_id.placeholder')}
            errors={errors}
          />
          <Divider small />

          <Control.Input
            id={buildPrefix('content.filters.user_data')}
            {...register(buildPrefix('content.filters.user_data'), {
              maxLength: validate.maxLengthValidator(128),
            })}
            label={t('tasks:GCTask.form.content.filters.user_data.label')}
            placeholder={t('tasks:GCTask.form.content.filters.user_data.placeholder')}
            errors={errors}
          />
          <Divider small />

          <Control.DateInput
            id={buildPrefix('content.filters.create_time__gte')}
            name={buildPrefix('content.filters.create_time__gte')}
            label={t('tasks:GCTask.form.content.filters.create_time__gte.label')}
            control={control}
            data-testid="create_time__gte"
            enableTime
          />
          <Divider small />

          <Control.DateInput
            id={buildPrefix('content.filters.create_time__lt')}
            name={buildPrefix('content.filters.create_time__lt')}
            label={t('tasks:GCTask.form.content.filters.create_time__lt.label')}
            control={control}
            data-testid="create_time__lt"
            enableTime
          />
        </>
      )}

      {targetValue === 'face_descriptors' && (
        <Control.Select
          id={buildPrefix('content.filters.descriptor_version')}
          name={buildPrefix('content.filters.descriptor_version')}
          label={t('tasks:GCTask.form.content.filters.descriptor_version.label')}
          placeholder={t('tasks:GCTask.form.content.filters.descriptor_version.placeholder')}
          control={control}
          options={descriptorVersions}
          rules={{
            required: validate.requiredValidator(),
            validate: ({ value }) => validate.intValidator()(value),
          }}
          errors={errors}
          isCreatable
          isClearable
        />
      )}
    </>
  );
}

GarbageCollectionTargetSubFormComponent.propTypes = {
  prefix: PropTypes.string,
  LISAvailability: PropTypes.shape({
    taskResults: PropTypes.bool,
    facesSamples: PropTypes.bool,
    bodiesSamples: PropTypes.bool,
  }),
  isLunaEventsAvailable: PropTypes.bool,
  descriptorVersions: PropTypes.arrayOf(PropTypes.any),
};
GarbageCollectionTargetSubFormComponent.defaultProps = {
  prefix: '',
  LISAvailability: undefined,
  isLunaEventsAvailable: false,
  descriptorVersions: [],
};

export const GarbageCollectionTargetSubForm = connect((state) => ({
  descriptorVersions: selectDescriptorVersionOptions(state),
  LISAvailability: selectLISAvailability(state),
  isLunaEventsAvailable: selectServiceAvailability(state, 'luna-events'),
}))(GarbageCollectionTargetSubFormComponent);
