import { createSelector } from '@reduxjs/toolkit';

export const selectTaskSlice = (state) => state.task;

export const selectTaskInfo = createSelector(
  selectTaskSlice,
  ({ task }) => task,
);
export const selectTaskSubtasks = createSelector(
  selectTaskSlice,
  ({ subtasks }) => subtasks,
);
export const selectTaskResult = createSelector(
  selectTaskSlice,
  ({ result }) => result,
);
export const selectTaskErrors = createSelector(
  selectTaskSlice,
  ({ errors }) => errors,
);
