export const hydrateFormFrom = ({ task, trigger }) => ({
  task: {
    task_type: task.task_type,
    content: { ...task.content },
  },
  trigger: { ...trigger },
  behaviour: {
    start_immediately: false,
    create_stopped: false,
  },
});
