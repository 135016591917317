import React, { useEffect } from 'react';

import {
  ClipboardIcon, UsersIcon, LogOutIcon, CircleInfoIcon, CalendarIcon,
} from '@vlabs/icons';
import { Layout, Pages, Account } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.sass';

import { selectAuth } from 'features/auth/selectors';
import { checkAuth, logout } from 'features/auth/store';

import AccountListPage from 'features/account-list/AccountListPage';
import AccountPage from 'features/account/AccountPage';
import LoginPage from 'features/auth/LoginPage';
import InfoPage from 'features/info/InfoPage';
import { ScheduleListPage } from 'features/schedules/ScheduleListPage';
import TaskListPage from 'features/task-list/TaskListPage';
import TaskPage from 'features/task/TaskPage';

function App() {
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth);
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Layout.Base>
        {!isAuthenticated && (
          <Switch>
            <Route path="*" component={LoginPage} />
          </Switch>
        )}
        {isAuthenticated && (
        <>
          <div className="App__Sticky">
            <Layout.Navbar>
              <Layout.NavbarLink icon={<UsersIcon />} to="/accounts">{t('accounts:название раздела')}</Layout.NavbarLink>
              <Layout.NavbarLink icon={<ClipboardIcon />} to="/tasks">{t('tasks:название раздела')}</Layout.NavbarLink>
              <Layout.NavbarLink icon={<CalendarIcon />} to="/schedules">{t('schedules:название раздела')}</Layout.NavbarLink>
              <Layout.NavbarLink icon={<CircleInfoIcon />} to="/info">{t('info:название раздела')}</Layout.NavbarLink>
              <Account>
                <button type="button" className="App__Link App__Link_btn" onClick={() => dispatch(logout)}>
                  <LogOutIcon className="App__Icon" />
                  {t('выйти')}
                </button>
              </Account>
            </Layout.Navbar>
          </div>
          <Layout.Content>
            <Switch>
              <Route
                path="/tasks"
                render={() => (<TaskListPage />)}
                exact
              />
              <Route
                path="/tasks/:taskId"
                component={TaskPage}
                exact
              />
              <Route
                path="/schedules"
                component={ScheduleListPage}
                exact
              />
              <Route
                path="/accounts"
                render={() => (<AccountListPage />)}
                exact
              />
              <Route
                path="/accounts/:accountId"
                component={AccountPage}
                exact
              />
              <Route
                path="/info"
                render={() => (<InfoPage />)}
              />
              <Route exact path="/" render={() => (<Redirect to="/accounts" />)} />
              <Route path="*" component={Pages.NotFoundPage} />
            </Switch>
          </Layout.Content>
        </>
        )}
      </Layout.Base>
    </BrowserRouter>
  );
}

export default App;
