import { i18n as uikitTranslations } from '@vlabs/uikit';

import accounts from './accounts.json';
import api from './api.json';
import common from './common.json';
import info from './info.json';
import lunaAdmin from './lunaAdmin.json';
import lunaTasks from './lunaTasks.json';
import schedules from './schedules.json';
import tasks from './tasks.json';

export default {
  ...uikitTranslations.en,
  accounts,
  api,
  common,
  info,
  lunaAdmin,
  lunaTasks,
  schedules,
  tasks,
};
