import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../constants';

export const postEventsSchema = new Schema({
  target: ENUMS.TARGET.events.value,
  store_results: get('store_results', true).asBoolean(),
  remove_samples: get('remove_samples', false).asBoolean(),
  remove_image_origins: get('remove_image_origins', false).asBoolean(),
  filters: get('filters').asSchema(new Schema({
    account_id: get('account_id').ifEmptyString(undefined),
    create_time__lt: get('create_time__lt.0').asISOString(),
    insert_time__lt: get('insert_time__lt.0').asISOString(),
    handler_id: get('handler_id').ifEmptyString(undefined),
  })),
});

export const postEventDescriptorsSchema = new Schema({
  target: ENUMS.TARGET.event_descriptors.value,
  store_results: get('store_results', true).asBoolean(),
  filters: get('filters').asSchema(new Schema({
    descriptor_version: get('descriptor_version.value').asInt(),
    descriptor_type: get('descriptor_type').asOptionValue(),
  })),
});

export const postFacesSchema = new Schema({
  target: ENUMS.TARGET.faces.value,
  store_results: get('store_results', true).asBoolean(),
  remove_samples: get('remove_samples', false).asBoolean(),
  filters: get('filters').asSchema(new Schema({
    account_id: get('account_id').ifEmptyString(undefined),
    create_time__lt: get('create_time__lt.0').asISOString(),
    create_time__gte: get('create_time__gte.0').asISOString(),
    user_data: get('user_data').ifEmptyString(undefined),
    list_id: get('list_id').switch((list) => list.value !== undefined, {
      true: (v) => v.value,
      default: (v) => v,
    }).ifEmptyString(undefined),
  })),
});

export const postFaceDescriptorsSchema = new Schema({
  target: ENUMS.TARGET.face_descriptors.value,
  store_results: get('store_results', true).asBoolean(),
  filters: get('filters').asSchema(new Schema({
    descriptor_version: get('descriptor_version.value').asInt(),
  })),
});
