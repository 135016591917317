import React, { useState } from 'react';

import { XIcon } from '@vlabs/icons';
import { Modal as KitModal } from '@vlabs/uikit';

import st from './styles.module.sass';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return {
    isOpen,
    close,
    open,
    wrap: (component) => {
      return (
        <KitModal
          isOpen={isOpen}
          onRequestClose={close}
          appElement={document.getElementById('root')}
        >
          <div>
            <XIcon
              onClick={close}
              className={st.CloseButton}
            />
            {component}
          </div>
        </KitModal>
      );
    },
  };
};

export default useModal;
