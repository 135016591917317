import React, { useEffect } from 'react';

import { Table, Panel, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './Account.module.sass';

const infoLabel = (label, value) => (
  <p className={st.Row}>
    <span className={st.Label}>{label}</span>
    <span className={st.Value}>{value}</span>
  </p>
);

function AccountInfo({
  fetchAccount,
  account,
}) {
  const { t } = useTranslation();
  useEffect(() => { fetchAccount(); }, [fetchAccount]);

  return (
    <>
      <h6>{t('accounts:общая информация.заголовок')}</h6>
      <div className={st.Info}>
        {infoLabel(t('accounts:account.login'), account?.login)}
        {infoLabel(t('accounts:account.description'), account?.description)}
        {infoLabel(t('accounts:account.account_type'), account?.account_type)}
        {infoLabel(t('accounts:account.account_id'), account?.account_id)}
      </div>
      <Divider small />
      <Panel>
        <Table
          data={account ? [account] : []}
          columns={[
            { Header: t('accounts:таблица.колонки.list_count'), accessor: 'list_count', width: 100 },
            { Header: t('accounts:таблица.колонки.face_attribute_count'), accessor: 'face_attribute_count', width: 100 },
            { Header: t('accounts:таблица.колонки.face_count'), accessor: 'face_count', width: 100 },
          ]}
        />
      </Panel>
    </>
  );
}

AccountInfo.propTypes = {
  fetchAccount: PropTypes.func.isRequired,
  account: PropTypes.shape({
    login: PropTypes.string,
    description: PropTypes.string,
    account_id: PropTypes.string,
    account_type: PropTypes.string,
  }),
};

AccountInfo.defaultProps = {
  account: undefined,
};

export default AccountInfo;
