import { createSlice } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

import { LunaAdminClient } from 'api';
import { LunaError } from 'api-bindings/luna-client/LunaError';
import i18n from 'translations/i18n';

const initialState = {
  state: undefined,
  data: [],
  byId: {},
  meta: {},
  pageIndex: 0,
  pageSize: 10,
  filters: [],
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'taskList',
  initialState,
  reducers: {
    setTasks(state, { payload }) {
      state.data = payload.data;
      state.meta = payload.meta;
      (payload.data || []).forEach((task) => {
        state.byId[task.task_id] = task;
      });
      state.state = 'loaded';
    },
    setPageIndex(state, { payload }) {
      state.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      state.pageSize = payload;
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    resetStore() { return initialState; },
  },
});

export default store.reducer;

export const { resetStore, setPageIndex, setPageSize, setFilters } = store.actions;

export const fetchTasks = async (dispatch, getState) => {
  const {
    taskList: { pageIndex, pageSize, filters },
  } = getState();

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (id === 'create_time') {
      localFilters.create_time__gte = value?.[0]?.toISOString();
      localFilters.create_time__lt = value?.[1]?.toISOString();
      return;
    }

    if (id === 'end_time') {
      localFilters.end_time__gte = value?.[0]?.toISOString();
      localFilters.end_time__lt = value?.[1]?.toISOString();
      return;
    }

    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      if (id === 'task_id') localFilters.task_ids = value;
      localFilters[id] = value;
    }
  });

  try {
    const params = { page: pageIndex + 1, page_size: pageSize, ...localFilters };
    const [
      { data: { tasks } },
      { data: { count } },
    ] = await Promise.all([
      LunaAdminClient.tasks.showAll(params),
      LunaAdminClient.tasks.getCount(params),
    ]);
    dispatch(store.actions.setTasks({ data: tasks, meta: { count } }));
  } catch (error) {
    dispatch(store.actions.setTasks({ data: [] }));
    if (error instanceof LunaError) {
      if (error.error_code === 11039) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'Luna Tasks service is required for task' }), { toastId: error.error_code });
        return;
      }
    }
    throw error;
  }
};

export const getTaskResult = async (taskId) => {
  const { data } = await LunaAdminClient.tasks.showResultsById(taskId);
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  FileSaver.saveAs(blob, `${taskId}.json`);
};

export const deleteTask = (task_id) => async (dispatch) => {
  await LunaAdminClient.tasks.delete(task_id);
  dispatch(fetchTasks);
  toast.info(i18n.t('tasks:api.task.удаление.успех', { task: { task_id } }));
};

export const cancelTask = (taskId) => async (dispatch) => {
  await LunaAdminClient.tasks.cancel(taskId);
  dispatch(fetchTasks);
};

export const createGCTask = (params) => async (dispatch) => {
  try {
    const { data: { task_id } } = await LunaAdminClient.tasks.createGCTask(params);
    dispatch(fetchTasks);
    toast.info(i18n.t('tasks:api.GCTask.создание.успех', { task: { ...params, task_id } }));
  } catch (error) {
    if (error instanceof LunaError) {
      if ([12022, 12027].includeserror.error_code && error.detail.includes('descriptor')) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'descriptor version is not supported for target' }), { toastId: error.error_code });
        return;
      }
      if (error.error_code === 11039) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'Luna Tasks service is required to start garbage collection task' }), { toastId: error.error_code });
        return;
      }
      if (error.error_code === 28021) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'Luna Events service is required to garbage collect events related data' }), { toastId: error.error_code });
      }
    }
    throw error;
  }
};

export const createAdditionalExtractionTask = (params) => async (dispatch) => {
  try {
    const { data: { task_id } } = await LunaAdminClient.tasks.createAdditionalExtractionTask(params);
    dispatch(fetchTasks);
    toast.info(i18n.t('tasks:api.additionalExtraction.создание.успех', { task: { ...params, task_id } }));
  } catch (error) {
    if (error instanceof LunaError) {
      if ([12022, 12027].includeserror.error_code && error.detail.includes('descriptor')) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'descriptor version is not supported for target' }), { toastId: error.error_code });
        return;
      }
      if (error.error_code === 11039) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'Luna Tasks service is required to start garbage collection task' }), { toastId: error.error_code });
        return;
      }
      if (error.error_code === 11070) {
        toast.error(i18n.t('api:ошибка.сообщение', { error: 'Luna Image Store service is required to start additional extraction task' }), { toastId: error.error_code });
      }
    }
    throw error;
  }
};
