import { utils } from '@vlabs/uikit/utils';

import i18n from 'translations/i18n';

export const ROLE_OPTIONS = utils.createProxy([
  { value: 0, label: i18n.t('constants:пользователь') },
  { value: 2, label: i18n.t('constants:администратор') },
]);

export const GENDER_OPTIONS = utils.createProxy([
  { value: 0, label: i18n.t('constants:женский') },
  { value: 1, label: i18n.t('constants:мужской') },
  { value: 'null', label: i18n.t('constants:оценка не проводилась') },
]);

export const EVENT_OPTIONS = utils.createProxy([
  { value: 'faces', label: i18n.t('constants:лиц') },
  { value: 'events', label: i18n.t('constants:событий') },
]);

export const OBJECT_TYPES_OPTIONS = utils.createProxy([
  { value: 'faces', label: i18n.t('constants:лица') },
  { value: 'events', label: i18n.t('constants:события') },
]);

export const ETHNIC_OPTIONS = utils.createProxy([
  { value: 1, label: i18n.t('constants:афроамериканская'), description: 'african_american' },
  { value: 2, label: i18n.t('constants:индийская'), description: 'indian' },
  { value: 3, label: i18n.t('constants:азиатская'), description: 'asian' },
  { value: 4, label: i18n.t('constants:европеоидная'), description: 'caucasian' },
  { value: 'null', label: i18n.t('constants:оценка не проводилась') },
]);

export const EMOTION_OPTIONS = utils.createProxy([
  { value: 1, label: i18n.t('constants:гнев') },
  { value: 2, label: i18n.t('constants:отвращение') },
  { value: 3, label: i18n.t('constants:страх') },
  { value: 4, label: i18n.t('constants:счастье') },
  { value: 5, label: i18n.t('constants:нейтральность') },
  { value: 6, label: i18n.t('constants:грусть') },
  { value: 7, label: i18n.t('constants:удивление') },
  { value: 'null', label: i18n.t('constants:оценка не проводилась') },
]);

export const MASK_OPTIONS_STRICT = utils.createProxy([
  { label: i18n.t('constants:masks.missing'), value: 1 },
  { label: i18n.t('constants:masks.medical_mask'), value: 2 },
  { label: i18n.t('constants:masks.occluded'), value: 3 },
]);

export const MASK_OPTIONS = utils.createProxy([
  ...MASK_OPTIONS_STRICT.raw,
  { value: 'null', label: i18n.t('constants:оценка не проводилась') },
]);

export const TYPE_OPTIONS = [
  { value: 'image_search', label: i18n.t('constants:поиск по изображению') },
  { value: 'file_export', label: i18n.t('constants:экспорт в файл') },
  { value: 'unknown', label: i18n.t('constants:неизвестный') },
];

export const STATUS_OPTIONS = [
  { value: 0, label: i18n.t('constants:в ожидании') },
  { value: 1, label: i18n.t('constants:запущено') },
  { value: 2, label: i18n.t('constants:готово') },
  { value: 3, label: i18n.t('constants:прервано') },
  { value: 4, label: i18n.t('constants:отменено') },
];
export const TIME_TYPE_OPTIONS = utils.createProxy([
  { value: 'sec', label: i18n.t('constants:секунды') },
  { value: 'frames', label: i18n.t('constants:кадры') },
]);

export const CAMERA_PROTOCOL_OPTIONS = utils.createProxy([
  { value: 'tcp', label: i18n.t('constants:tcp') },
  { value: 'udp', label: i18n.t('constants:udp') },
]);

export const CAMERA_ROTATION_OPTIONS = utils.createProxy([
  { value: 0, label: 0 },
  { value: 90, label: 90 },
  { value: 180, label: 180 },
  { value: 270, label: 270 },
]);

export const LIVENESS_MODE_OPTIONS = utils.createProxy([
  { value: 0, label: i18n.t('constants:первые n кадров') },
  { value: 1, label: i18n.t('constants:последние n кадров перед отправкой лучшего кадра') },
  { value: 2, label: i18n.t('constants:все кадры трека') },
]);

export const CAMERA_STATUS_OPTIONS = utils.createProxy([
  { value: 0, label: i18n.t('constants:stopped') },
  { value: 1, label: i18n.t('constants:active') },
  { value: 2, label: i18n.t('constants:connecting') },
  { value: 3, label: i18n.t('constants:failed') },
]);

export const TASKS_TYPE_OPTIONS = utils.createProxy([
  { value: 0, label: i18n.t('constants:неизвестный') },
  { value: 1, label: i18n.t('constants:компоновка') },
  { value: 2, label: i18n.t('constants:кластеризация') },
  { value: 3, label: i18n.t('constants:отчет') },
  { value: 4, label: i18n.t('constants:сборка мусора') },
  { value: 5, label: i18n.t('constants:повторное извлечение') },
  { value: 6, label: i18n.t('constants:кросс-матчинг') },
  { value: 7, label: i18n.t('constants:расчет roc-кривой') },
  { value: 8, label: i18n.t('constants:экспорт') },
  { value: 9, label: i18n.t('constants:пакетная обработка') },
]);

export const TASKS_STATUS_OPTIONS = [
  { value: 0, label: i18n.t('constants:в ожидании') },
  { value: 1, label: i18n.t('constants:в процессе') },
  { value: 2, label: i18n.t('constants:отменена') },
  { value: 3, label: i18n.t('constants:ошибка') },
  { value: 4, label: i18n.t('constants:сбор результатов') },
  { value: 5, label: i18n.t('constants:готово') },
];

export const DESCRIPTOR_TYPE_OPTIONS = utils.createProxy([
  { value: 'face', label: i18n.t('constants:лица') },
  { value: 'body', label: i18n.t('constants:тела') },
]);

export const MULTIFACE_POLICY_OPTIONS = utils.createProxy([
  { label: i18n.t('constants:отбраковывать'), value: 0 },
  { label: i18n.t('constants:не отбраковывать'), value: 1 },
  { label: i18n.t('constants:выбирать только одно лицо наилучшего качества'), value: 2 },
]);

export const AGE_OPTIONS = utils.createProxy([
  { label: i18n.t('constants:младше 18'), value: [1, 18] },
  { label: i18n.t('constants:от 18 до 44'), value: [18, 44] },
  { label: i18n.t('constants:от 45 до 60'), value: [45, 60] },
  { label: i18n.t('constants:старше 60'), value: [60, 100] },
], {
  get(target, prop) {
    if (prop === 'raw') return target;

    if (prop.includes('_')) {
      let [gte, lt] = prop.split('_');
      gte = parseFloat(gte);
      if (Number.isNaN(gte)) gte = undefined;
      lt = parseFloat(lt);
      if (Number.isNaN(lt)) lt = undefined;

      if (gte === undefined && lt === undefined) return null;

      const result = target.find(({ value }) => (
        gte === value[0] && lt === value[1]
      ));

      return result ?? { label: i18n.t('Иное'), value: [gte, lt] };
    }
    const numberedProp = parseFloat(prop);
    if (Number.isNaN(numberedProp)) return null;

    const result = target.find(({ value: [gte, lt] }) => (
      numberedProp >= gte && numberedProp < lt
    ));

    return result ?? null;
  },
});

export const TEMPERATURE_OPTIONS = utils.createProxy([
  { value: 't#normal', label: i18n.t('constants:температура.нормальная', { context: 'сокращённое' }) },
  { value: 't#high', label: i18n.t('constants:температура.повышенная', { context: 'сокращённое' }) },
  { value: 't#abnormal', label: i18n.t('constants:температура.аномальная', { context: 'сокращённое' }) },
]);

export const TEMPERATURE_TAG_OPTIONS = utils.createProxy([
  { value: 't#normal', label: i18n.t('constants:температура.нормальная') },
  { value: 't#high', label: i18n.t('constants:температура.повышенная') },
  { value: 't#abnormal', label: i18n.t('constants:температура.аномальная') },
]);

export const EYE_STATUS_OPTIONS = utils.createProxy([
  { value: 'open', label: i18n.t('constants:открыт') },
  { value: 'closed', label: i18n.t('constants:закрыт') },
  { value: 'occluded', label: i18n.t('constants:перекрытие') },
]);

export const MOUTH_STATUS_OPTIONS = utils.createProxy([
  { value: 'opened', label: i18n.t('constants:открыт') },
  { value: 'smile', label: i18n.t('constants:улыбка') },
  { value: 'occluded', label: i18n.t('constants:перекрытие') },
]);

export const DEEPFAKE_STATE_OPTIONS_STRICT = utils.createProxy([
  { value: 0, label: i18n.t('constants:deepfake.state.fake') },
  { value: 1, label: i18n.t('constants:deepfake.state.real') },
]);
export const DEEPFAKE_STATE_OPTIONS = utils.createProxy([
  ...DEEPFAKE_STATE_OPTIONS_STRICT.raw,
  { value: 'null', label: i18n.t('constants:deepfake.state.null') },
]);

export const DEEPFAKE_MODE_OPTIONS = utils.createProxy([
  { value: 1, label: i18n.t('constants:deepfake.mode.swap') },
  { value: 2, label: i18n.t('constants:deepfake.mode.swap+synt') },
]);
