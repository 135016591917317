// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/gc.py
import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../../constants';
import { baseTask } from './base';
import { accountFilter } from './filters';

export const baseWithDescriptorVersionFilter = {
  descriptor_version: get('descriptor_version').switch((v) => typeof v, {
    object: ({ value }) => value,
    default: (v) => v,
  }).asInt().ifNaN(undefined),
};
export const eventDescriptorVersion = {
  ...baseWithDescriptorVersionFilter,
  descriptor_type: get('descriptor_type').asOptionValue(),
};
export const faceDescriptorFilters = {
  ...baseWithDescriptorVersionFilter,
};
export const eventFilters = {
  ...accountFilter,
  handler_id: get('handler_id').asString().ifEmptyString(undefined),
  create_time__lt: get('create_time__lt').asISOString({ unpack: true }),
  insert_time__lt: get('insert_time__lt').asISOString({ unpack: true }),
};
export const faceFilters = {
  ...accountFilter,
  list_id: get('list_id').asString().ifEmptyString(undefined),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  create_time__lt: get('create_time__lt').asISOString({ unpack: true }),
  create_time__gte: get('create_time__gte').asISOString({ unpack: true }),
};

export const content = (_content) => {
  const target = get('target')(_content);
  const baseSchema = {
    target: get('target').asOptionValue(),
    store_results: get('store_results').asBoolean(),
  };

  let schema;
  switch (target?.value) {
    case ENUMS.TARGET.events.value: {
      schema = new Schema({
        ...baseSchema,
        filters: get('filters').asSchema(new Schema(eventFilters)),
        remove_samples: get('remove_samples').asBoolean(),
        remove_image_origins: get('remove_image_origins').asBoolean(),
      });
      break;
    }
    case ENUMS.TARGET.event_descriptors.value: {
      schema = new Schema({
        ...baseSchema,
        filters: get('filters').asSchema(new Schema(eventDescriptorVersion)),
        store_results: get('store_results').asBoolean(),
      });
      break;
    }
    case ENUMS.TARGET.faces.value: {
      schema = new Schema({
        ...baseSchema,
        filters: get('filters').asSchema(new Schema(faceFilters)),
        store_results: get('store_results').asBoolean(),
        remove_samples: get('remove_samples').asBoolean(),
      });
      break;
    }
    case ENUMS.TARGET.face_descriptors.value: {
      schema = new Schema({
        ...baseSchema,
        filters: get('filters').asSchema(new Schema(faceDescriptorFilters)),
        store_results: get('store_results').asBoolean(),
      });
      break;
    }
    default: {
      throw Error(`"${target}" target does not defined`);
    }
  }

  return schema(_content);
};

export const gcTask = new Schema({
  ...baseTask,
  content: get('content').asSchema(content),
});
