import React from 'react';

import { Table, Panel, TableCells } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function AccountLists(props) {
  const { t } = useTranslation();

  return (
    <Panel>
      <Table
        {...props}
        paginationType="offsetBased"
        columns={[
          { Header: t('accounts:списки.таблица.колонки.list_id'), accessor: 'list_id' },
          { Header: t('accounts:списки.таблица.колонки.user_data'), accessor: 'user_data' },
          { Header: t('accounts:списки.таблица.колонки.face_count'), accessor: 'face_count' },
          { Header: t('accounts:списки.таблица.колонки.last_update_time'), accessor: 'last_update_time', Cell: TableCells.DateCell },
        ]}
      />
    </Panel>
  );
}

AccountLists.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    count: PropTypes.number,
  }),
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
};

AccountLists.defaultProps = {
  data: [],
  meta: { count: 0 },
  pageIndex: 0,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
};

export default AccountLists;
