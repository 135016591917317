import { isValidCron } from 'cron-validator';
import i18n from 'i18next';

export const regexValidator = (regex, message) => (value) => {
  if ((value?.length || 0) === 0) return true;
  if (!value.match(regex)) return message || i18n.t('валидация.regex', { regex });
  return true;
};
export const maxLengthValidator = (value, message) => ({
  value,
  message: message || i18n.t('валидация.длина.максимум', { count: value }),
});
export const minLengthValidator = (value, message) => ({
  value,
  message: message || i18n.t('валидация.длина.минимум', { count: value }),
});
export const requiredValidator = (message) => message ?? i18n.t('валидация.обязательно для заполнения');

export const UUID_PATTERN = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
export const EMAIL_PATTERN = /^[a-z0-9_'+\-.]+@[a-z0-9]+\.[a-z]{2,}$/;

export const UUIDValidator = (message) => (value) => regexValidator(
  UUID_PATTERN,
  message ?? i18n.t('валидация.uuid'),
)(value);

export const emailValidator = (message) => (value) => regexValidator(
  EMAIL_PATTERN,
  message ?? i18n.t('валидация.email'),
)(value);

export const intValidator = (message) => (value) => {
  if (Number.isInteger(Number(value))) return true;
  return message || i18n.t('валидация.число.целое');
};
export const multipleUUIDValidator = (value) => {
  let formattedValue = Array.isArray(value) ? value : [];

  if (value && typeof value === 'string') {
    formattedValue = value?.split(',');
  }

  formattedValue.filter((v) => v?.length).map((v) => v.trim());

  if (!formattedValue?.length) return true;
  const messages = [];
  const isValid = formattedValue.every((item) => {
    const result = UUIDValidator(item);
    messages.push(result);
    if (result) return true;
    return result;
  });
  if (!isValid) return messages;
  return true;
};

export const cronValidator = (message) => (value) => {
  if (value === undefined || value === '') return true;
  if (!isValidCron(value)) return message ?? i18n.t('валидация.cron');
  return true;
};
