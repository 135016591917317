import React from 'react';

import { errorToasts } from '@vlabs/uikit';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { LunaError } from 'api-bindings/luna-client/LunaError';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './index.sass';
import 'styles/toasts.sass';

errorToasts.registerErrorHandlers(
  {
    condition: (e) => e instanceof LunaError,
    handler: (e) => errorToasts.showLunaErrorToast(e, 'Luna Admin API'),
  },
  errorToasts.axiosErrorHandler,
  errorToasts.defaultErrorHandler,
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer position={toast.POSITION.TOP_LEFT} icon={false} />
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
