import React from 'react';

import { PauseCircleIcon, StopCircleIcon, PlayCircleIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const buttonPropTypes = {
  value: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
};

const defaultButtonPropTypes = {};

function StartCellComponent({
  value: {
    onClick,
    objectId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      onClick={() => onClick(objectId)}
      title={t('schedules:table.actions.start')}
      variant="dimmed"
      kind="primary"
      disabled={disabled}
      data-testid="button-play"
      icon={<PlayCircleIcon data-testid="icon-play" />}
    />
  );
}

StartCellComponent.propTypes = buttonPropTypes;
StartCellComponent.defaultProps = defaultButtonPropTypes;

function PauseCellComponent({
  value: {
    onClick,
    objectId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      onClick={() => onClick(objectId)}
      title={t('schedules:table.actions.pause')}
      variant="dimmed"
      kind="primary"
      disabled={disabled}
      data-testid="button-pause"
      icon={<PauseCircleIcon data-testid="icon-pause" />}
    />
  );
}

PauseCellComponent.propTypes = buttonPropTypes;
PauseCellComponent.defaultProps = defaultButtonPropTypes;

function StopCellComponent({
  value: {
    onClick,
    objectId,
    disabled,
  },
}) {
  const { t } = useTranslation();
  return (
    <Control.RoundButton
      onClick={() => onClick(objectId)}
      title={t('schedules:table.actions.stop')}
      variant="dimmed"
      kind="primary"
      disabled={disabled}
      data-testid="button-stop"
      icon={<StopCircleIcon data-testid="icon-stop" />}
    />
  );
}

StopCellComponent.propTypes = buttonPropTypes;
StopCellComponent.defaultProps = defaultButtonPropTypes;

export const StartCell = StartCellComponent;
export const PauseCell = PauseCellComponent;
export const StopCell = StopCellComponent;
