import { utils } from '@vlabs/uikit/utils';

import i18n from 'translations/i18n';

export const OBJECTS_TYPE = utils.createProxy([
  { value: 'events', label: i18n.t('lunaTasks:enums.objects_type.events') },
  { value: 'faces', label: i18n.t('lunaTasks:enums.objects_type.faces') },
]);

export const TARGET = utils.createProxy([
  { value: 'events', label: i18n.t('lunaTasks:enums.target.events') },
  { value: 'event_descriptors', label: i18n.t('lunaTasks:enums.target.event_descriptors') },
  { value: 'faces', label: i18n.t('lunaTasks:enums.target.faces') },
  { value: 'face_descriptors', label: i18n.t('lunaTasks:enums.target.face_descriptors') },
]);

export const DESCRIPTOR_TYPE = utils.createProxy([
  { value: 'face', label: i18n.t('lunaTasks:enums.descriptor_type.face') },
  { value: 'body', label: i18n.t('lunaTasks:enums.descriptor_type.body') },
]);

export const EXTRACTION_TARGET = utils.createProxy([
  { value: 'basic_attributes', label: i18n.t('lunaTasks:enums.extraction_target.basic_attributes') },
  { value: 'face_descriptor', label: i18n.t('lunaTasks:enums.extraction_target.face_descriptor') },
  { value: 'body_descriptor', label: i18n.t('lunaTasks:enums.extraction_target.body_descriptor') },
]);

export const MATCH_TYPE = utils.createProxy([
  { value: 'faces', label: i18n.t('lunaTasks:enums.objects_type.faces') },
  { value: 'events', label: i18n.t('lunaTasks:enums.objects_type.events') },
  { value: 'attributes', label: i18n.t('lunaTasks:enums.objects_type.attributes') },
]);

export const TASK_TYPE = utils.createProxy([
  { value: 0, label: i18n.t('lunaTasks:enums.task_type.0') },
  { value: 1, label: i18n.t('lunaTasks:enums.task_type.1') },
  { value: 2, label: i18n.t('lunaTasks:enums.task_type.2') },
  { value: 3, label: i18n.t('lunaTasks:enums.task_type.3') },
  { value: 4, label: i18n.t('lunaTasks:enums.task_type.4') },
  { value: 5, label: i18n.t('lunaTasks:enums.task_type.5') },
  { value: 6, label: i18n.t('lunaTasks:enums.task_type.6') },
  { value: 7, label: i18n.t('lunaTasks:enums.task_type.7') },
  { value: 8, label: i18n.t('lunaTasks:enums.task_type.8') },
  { value: 9, label: i18n.t('lunaTasks:enums.task_type.9') },
]);

export const TASK_STATUS = utils.createProxy([
  { value: 0, label: i18n.t('lunaTasks:enums.task_status.0') },
  { value: 1, label: i18n.t('lunaTasks:enums.task_status.1') },
  { value: 2, label: i18n.t('lunaTasks:enums.task_status.2') },
  { value: 3, label: i18n.t('lunaTasks:enums.task_status.3') },
  { value: 4, label: i18n.t('lunaTasks:enums.task_status.4') },
  { value: 5, label: i18n.t('lunaTasks:enums.task_status.5') },
]);

export const SCHEDULE_CRON_TIMEZONE = utils.createProxy([
  { value: 'local', label: i18n.t('lunaTasks:enums.cron_timezone.local') },
  { value: 'utc', label: i18n.t('lunaTasks:enums.cron_timezone.utc') },
]);
export const SCHEDULE_STATUS = utils.createProxy([
  { value: 'running', label: i18n.t('lunaTasks:enums.schedule_status.running') },
  { value: 'stopped', label: i18n.t('lunaTasks:enums.schedule_status.stopped') },
]);

export const LUNA_TASK_ENUMS = {
  MATCH_TYPE,
  OBJECTS_TYPE,
  TARGET,
  TASK_TYPE,
  DESCRIPTOR_TYPE,
  EXTRACTION_TARGET,
  TASK_STATUS,
  SCHEDULE_CRON_TIMEZONE,
  SCHEDULE_STATUS,
};
