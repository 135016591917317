import React, { useEffect } from 'react';

import { Page, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AccountInfo from './AccountInfo';
import AccountLists from './AccountLists';
import { selectAccountInfo, selectAccountLists } from './selectors';
import * as store from './store';

const AccountPage = ({
  fetchAccount,
  resetStore,
  account,
  lists,
  setListsPageSize,
  setListsPageIndex,
}) => {
  const { t } = useTranslation();
  useEffect(() => resetStore, [resetStore]);

  return (
    <Page>
      <AccountInfo
        account={account}
        fetchAccount={fetchAccount}
      />

      <Divider />
      <h6>{t('accounts:списки.заголовок')}</h6>
      <Divider small />
      <AccountLists
        {...lists}
        setPageIndex={setListsPageIndex}
        setPageSize={setListsPageSize}
      />
    </Page>
  );
};

AccountPage.propTypes = {
  fetchAccount: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
  account: PropTypes.shape({}),
  lists: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    meta: PropTypes.shape({
      count: PropTypes.number,
    }),
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  setListsPageSize: PropTypes.func.isRequired,
  setListsPageIndex: PropTypes.func.isRequired,
};

AccountPage.defaultProps = {
  account: undefined,
  lists: {
    data: [],
    meta: { count: 0 },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

export default connect((state) => ({
  account: selectAccountInfo(state),
  lists: selectAccountLists(state),
}),
(dispatch, { match: { params: { accountId } } }) => ({
  fetchAccount: () => dispatch(store.fetchAccount(accountId)),
  resetStore: () => dispatch(store.resetStore()),
  setListsPageSize: (pageSize) => {
    dispatch(store.setListsPageSize(pageSize));
    dispatch(store.fetchAccountLists(accountId));
  },
  setListsPageIndex: (pageIndex) => {
    dispatch(store.setListsPageIndex(pageIndex));
    dispatch(store.fetchAccountLists(accountId));
  },
}))(AccountPage);
