import { isUndefined } from 'lodash';

import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../constants';
import { content as postAdditionalExtractionTaskContent } from './post/additionalExtract';
import { content as postGCTaskContent } from './post/gc';
import { content as postLinkerTaskContent } from './post/linker';

export default new Schema({
  task: {
    task_type: get('task.task_type').asOptionValue(),
    content: get('task').switch(({ task_type }) => task_type.value, {
      [ENUMS.TASK_TYPE[1].value]: ({ content }) => postLinkerTaskContent(content),
      [ENUMS.TASK_TYPE[4].value]: ({ content }) => postGCTaskContent(content),
      [ENUMS.TASK_TYPE[5].value]: ({ content }) => postAdditionalExtractionTaskContent(content),
    }),
  },
  trigger: {
    cron: get('trigger.cron').asString().ifEmptyString(undefined),
    cron_timezone: get('trigger.cron_timezone.value')
      .ifUndefined(ENUMS.SCHEDULE_CRON_TIMEZONE.local.value),
  },
  behaviour: {
    start_immediately: get('behaviour.start_immediately').asBoolean(),
    create_stopped: get('behaviour.create_stopped').asBoolean(),
  },
}).omitBy(isUndefined);
