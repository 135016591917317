import { createSlice } from '@reduxjs/toolkit';

import { LunaAdminClient } from 'api/index';

const initialState = {
  state: undefined,
  account: undefined,
  lists: {
    data: [],
    meta: { count: 0 },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

const store = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount(state, { payload }) {
      state.account = payload;
      state.state = 'loaded';
    },
    setLists(state, { payload }) {
      state.lists.data = payload.data;
      state.lists.meta = payload.meta;
    },
    setListsPageIndex(state, { payload }) {
      state.lists.pageIndex = payload;
    },
    setListsPageSize(state, { payload }) {
      state.lists.pageSize = payload;
    },
    resetStore() { return initialState; },
  },
});

export default store.reducer;

export const { resetStore, setListsPageIndex, setListsPageSize } = store.actions;

export const fetchAccount = (accountId) => async (dispatch) => {
  const { data: account } = await LunaAdminClient.accounts.show(accountId);
  dispatch(store.actions.setAccount({ ...account.info, ...account.stats }));
};

export const fetchAccountLists = (accountId) => async (dispatch, getState) => {
  const { account: { lists: { pageIndex, pageSize } } } = getState();
  const { data: { lists, list_count } } = await LunaAdminClient.lists.showAll({
    account_id: accountId,
    page: pageIndex + 1,
    page_size: pageSize,
  });
  dispatch(store.actions.setLists({
    data: lists,
    meta: { count: list_count },
  }));
};
