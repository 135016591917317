// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/filters.py
import { EMOTION_OPTIONS, ETHNIC_OPTIONS, GENDER_OPTIONS, MASK_OPTIONS } from 'api-bindings/constants';
import { get, Schema } from 'api-bindings/json-transform';
import { EVENT_ENUMS, LIVENESS_OPTIONS } from 'api-bindings/luna-client/constants';

export const accountFilter = {
  account_id: get('account_id').asString(),
};
export const optionalAccountFilter = {
  account_id: get('account_id').switch((v) => typeof v, {
    null: null,
    default: get().asString(),
  }),
};

const faceFilters = {
  face_ids: get('face_ids').asArrayOfOptions(),
  external_ids: get('external_ids').asArrayOfOptions(),
  user_data: get('user_data').asString(),
  create_time__lt: get('create_time__lt').asDate(),
  create_time__gte: get('create_time__gte').asDate(),
  face_id__lt: get('face_id__lt').asString(),
  face_id__gte: get('face_id__gte').asString(),
  list_id: get('list_id').asString(),
};

export const faceFiltersWithAccount = {
  ...accountFilter,
  ...faceFilters,
};
export const faceFiltersOptionalAccount = {
  ...optionalAccountFilter,
  ...faceFilters,
};

export const attributeFilters = {
  attribute_ids: get('attribute_ids').asArrayOfOptions(),
};

export const geopositionFilters = {
  origin_longitude: get('origin_longitude').asFloat().ifNaN(undefined),
  origin_latitude: get('origin_latitude').asFloat().ifNaN(undefined),
  longitude_delta: get('longitude_delta').asFloat().ifNaN(undefined),
  latitude_delta: get('latitude_delta').asFloat().ifNaN(undefined),
};

export const eventFilters = {
  create_time__lt: get('create_time__lt').asDate(),
  create_time__gte: get('create_time__gte').asDate(),
  end_time__lt: get('end_time__lt').asDate(),
  end_time__gte: get('end_time__gte').asDate(),
  sources: get('sources', []).ifNull([]).asArrayOfOptions(),
  stream_ids: get('stream_ids', []).ifNull([]).asArrayOfOptions(),
  event_ids: get('event_ids', []).asArrayOfOptions(),
  event_id__lt: get('event_id__lt', '').asString(),
  event_id__gte: get('event_id__gte', '').asString(),
  handler_ids: get('handler_ids', []).asArrayOfOptions(),
  top_matching_candidates_label: get('top_matching_candidates_label', []).asArrayOfOptions(),
  top_similar_object_ids: get('top_similar_object_ids', []).asArrayOfOptions(),
  top_similar_external_ids: get('top_similar_external_ids', []).asArrayOfOptions(),
  top_similar_object_similarity__lt: get('top_similar_object_similarity__lt').asFloat().ifNaN(undefined),
  top_similar_object_similarity__gte: get('top_similar_object_similarity__gte').asFloat().ifNaN(undefined),
  age__lt: get('age__lt').asInt().ifNaN(undefined),
  age__gte: get('age__gte').asInt().ifNaN(undefined),
  gender: get('gender').asOption(GENDER_OPTIONS),
  emotions: get('emotions', []).asArrayOfOptions(EMOTION_OPTIONS),
  ethnic_groups: get('ethnic_groups', []).asArrayOfOptions(ETHNIC_OPTIONS),
  face_ids: get('face_ids', []).asArrayOfOptions(),
  user_data: get('user_data', '').asString(),
  tags: get('tags', []).asArrayOfOptions(),
  cities: get('cities', []).asArrayOfOptions(),
  areas: get('areas', []).asArrayOfOptions(),
  districts: get('districts', []).asArrayOfOptions(),
  streets: get('streets', []).asArrayOfOptions(),
  house_numbers: get('house_numbers', []).asArrayOfOptions(),
  external_ids: get('external_ids', []).asArrayOfOptions(),
  geo_position: get('geo_position').asSchema(new Schema(geopositionFilters)),
  masks: get('masks', []).asArrayOfOptions(MASK_OPTIONS),
  track_ids: get('track_ids', []).asArrayOfOptions(),
  liveness: get('liveness', []).asArrayOfOptions(LIVENESS_OPTIONS),
  apparent_gender: get('apparent_gender', []).asArrayOfOptions(EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER),
  headwear_states: get('headwear_states', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.HEADWEAR),
  headwear_apparent_colors: get('headwear_apparent_colors', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS),
  sleeve_lengths: get('sleeve_lengths', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.SLEEVE),
  apparent_age__lt: get('apparent_age__lt').asInt().ifNaN(undefined),
  apparent_age__gte: get('apparent_age__gte').asInt().ifNaN(undefined),
  upper_clothing_colors: get('upper_clothing_colors', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR),
  backpack_states: get('backpack_states', []).asArrayOfOptions(EVENT_ENUMS.ACCESSORIES.BACKPACK),
  lower_garment_types: get('lower_garment_types', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE),
  lower_garment_colors: get('lower_garment_colors', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS),
  shoes_apparent_colors: get('shoes_apparent_colors', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR),
  meta: get('meta'),
};

export const eventFiltersWithAccount = {
  ...accountFilter,
  ...eventFilters,
};

export const eventFiltersOptionaAccount = {
  ...optionalAccountFilter,
  ...eventFilters,
};
