// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/bases.py
import { Schema, get } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../../constants';

export const descriptorType = {
  type: get('type').asOption(ENUMS.DESCRIPTOR_TYPE),
};

export const descriptorTypeBase = {
  descriptor: get('descriptor').asSchema(new Schema(descriptorType)),
};

export const baseTask = {
  schedule_id: get('schedule_id').asString(),
  task_type: get('task_type').asOption(ENUMS.TASK_TYPE),
  description: get('description').asString(),
  task_id: get('task_id').asInt(),
  create_time: get('create_time').ifNull(undefined),
  end_time: get('end_time').ifNull(undefined),
  last_update_time: get('last_update_time').ifNull(undefined),
  task_status: get('task_status').asOption(ENUMS.TASK_STATUS),
  result_id: get('result_id').asString(),
  count_task_parts_done: get('count_task_parts_done').asInt(),
  count_task_parts_all: get('count_task_parts_all').asInt(),
  content: get('content'),
};

export const baseTaskSchema = new Schema(baseTask);
