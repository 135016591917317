import { createSlice } from '@reduxjs/toolkit';

import { initApp } from 'features/app/store';

import { LunaError } from 'api-bindings/luna-client/LunaError';
import { LunaAdminClient } from 'api/index';

const store = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    model: null,
    data: [],
    meta: {},
  },
  reducers: {
    setAccount(state) {
      state.isAuthenticated = true;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
    },
  },
});

export default store.reducer;

export const login = (username, password) => async (dispatch) => {
  const result = await LunaAdminClient.auth.login(username, password);
  dispatch(store.actions.setAccount());
  dispatch(initApp);
  return result;
};

export const checkAuth = async (dispatch) => {
  try {
    await LunaAdminClient.auth.check();
    dispatch(store.actions.setAccount());
    dispatch(initApp);
  } catch (error) {
    if (error instanceof LunaError) {
      if (error.error.response.status === 401) return;
    }

    throw error;
  }
};

export const logout = async (dispatch) => {
  try {
    await LunaAdminClient.auth.logout();
    dispatch(store.actions.logoutSuccess());
    window.history.replaceState({}, '', '/');
  } catch (error) {
    if (error instanceof LunaError) {
      if (error.error.response.status === 404) {
        dispatch(store.actions.logoutSuccess());
        window.history.replaceState({}, '', '/');
        return;
      }
    }
    throw error;
  }
};
