import React, { useEffect, useCallback } from 'react';

import { Page, Divider, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ACCOUNT_TYPE } from 'api-bindings/luna-admin/constants';
import * as validate from 'forms/validators';

import st from './styles.module.sass';

const defaultValues = {
  account_type: ACCOUNT_TYPE.advanced_user,
};

function CreateAccountForm({
  onSubmit,
}) {
  const { t } = useTranslation();
  const {
    handleSubmit, register, setValue, clearErrors,
    setError, control, reset, formState: { errors },
  } = useForm(defaultValues);

  useEffect(() => reset(defaultValues), [reset]);

  const onGenerateUuid = useCallback(() => {
    setValue('account_id', uuidv4());
    clearErrors('account_id');
  }, [setValue, clearErrors]);

  const handleOnSubmit = async (values) => {
    const submitErrors = await onSubmit(values);
    if (submitErrors) {
      Object.entries(submitErrors).forEach(([fieldName, message]) => {
        setError(fieldName, {
          type: 'server error',
          message,
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Page title={t('accounts:форма.заголовок')} className={st.Root}>
        <Divider small />

        <label htmlFor="login">
          {t('accounts:форма.login.label')}
          <Control.Input
            id="login"
            {...register('login', {
              required: validate.requiredValidator(),
              minLength: validate.minLengthValidator(3),
              maxLength: validate.maxLengthValidator(128),
              validate: validate.emailValidator(),
            })}
            placeholder={t('accounts:форма.login.placeholder')}
            errors={errors}
          />
        </label>
        <Divider small />

        <label htmlFor="password">
          {t('accounts:форма.password.label')}
          <Control.Input
            id="password"
            {...register('password', {
              required: validate.requiredValidator(),
              minLength: validate.minLengthValidator(3),
              maxLength: validate.maxLengthValidator(128),
            })}
            placeholder={t('accounts:форма.password.placeholder')}
            errors={errors}
          />
        </label>
        <Divider small />

        <label htmlFor="account_type">
          {t('accounts:форма.account_type.label')}
          <Control.Select
            id="account_type"
            name="account_type"
            control={control}
            options={ACCOUNT_TYPE.raw}
            errors={errors}
            defaultValue={ACCOUNT_TYPE.advanced_user}
          />
        </label>
        <Divider small />

        <label htmlFor="description">
          {t('accounts:форма.description.label')}
          <Control.Input
            id="description"
            {...register('description', {
              maxLength: validate.maxLengthValidator(128),
            })}
            placeholder={t('accounts:форма.description.placeholder')}
            errors={errors}
          />
        </label>
        <Divider small />

        <label htmlFor="account_id">
          {t('accounts:форма.account_id.label')}
          <Control.Input
            id="account_id"
            {...register('account_id', {
              validate: validate.UUIDValidator(),
            })}
            placeholder={t('accounts:форма.account_id.placeholder')}
            errors={errors}
          />
        </label>
        <Divider small />

        <Control.Button fullWidth onClick={onGenerateUuid}>
          {t('accounts:форма.сгенерировать uuid')}
        </Control.Button>
        <Divider small />

        <Control.Button type="submit" fullWidth>
          {t('accounts:форма.submit', { context: 'create' })}
        </Control.Button>

      </Page>
    </form>
  );
}

CreateAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

CreateAccountForm.defaultProps = {
};

export default CreateAccountForm;
