// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/bases.py
import { Schema, get } from 'api-bindings/json-transform';

export const descriptorType = {
  type: get('type').asOptionValue(),
};

export const descriptorTypeBase = {
  descriptor: get('descriptor').asSchema(new Schema(descriptorType)),
};

export const baseTask = {
  task_type: get('task_type').asOptionValue(),
  description: get('description').asString().ifEmptyString(undefined),
  task_id: get('task_id').asInt().ifNaN(undefined),
  create_time: get('create_time').asISOString({ unpack: true }),
  end_time: get('end_time').asISOString({ unpack: true }),
  last_update_time: get('last_update_time').asISOString({ unpack: true }),
  task_status: get('task_status').asOptionValue(),
  result_id: get('result_id').asString().ifEmptyString(),
  count_task_parts_done: get('count_task_parts_done').asInt().ifNaN(undefined),
  count_task_parts_all: get('count_task_parts_all').asInt().ifNaN(undefined),
  content: get('content'),
};
