import React from 'react';

import { StopCircleIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';

import './CancelCell.sass';
import { withHandler } from 'components/hocs/withHandler';

const CancelCell = ({
  onClick,
  row: { original },
}) => {
  const { task_status: { value } } = original;
  if (![0, 1].includes(value)) return null;
  return (
    <StopCircleIcon
      data-testid="cancelCell"
      className="Icon"
      onClick={() => onClick(original)}
    />
  );
};

CancelCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      task_status: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default (onClick) => withHandler(onClick)(CancelCell);
