import { createSlice } from '@reduxjs/toolkit';

import { LunaAdminClient } from 'api';

const initialState = {
  state: undefined,
  task: undefined,
  subtasks: [],
  result: undefined,
  errors: {
    data: [],
    meta: {
      count: 0,
    },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

const store = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTask(state, { payload }) {
      state.task = payload;
    },
    setSubtasks(state, { payload }) {
      state.subtasks = payload;
    },
    setResult(state, { payload }) {
      state.result = payload;
    },
    setErrors(state, { payload }) {
      state.errors.data = payload.data;
      state.errors.meta = payload.meta;
    },
    setErrorsPageIndex(state, { payload }) {
      state.errors.pageIndex = payload;
    },
    setErrorsPageSize(state, { payload }) {
      state.errors.pageSize = payload;
    },
    resetStore() { return initialState; },
  },
});

export default store.reducer;

export const { resetStore, setErrorsPageIndex, setErrorsPageSize } = store.actions;

export const fetchTaskInfo = (taskId) => async (dispatch) => {
  const { data: task } = await LunaAdminClient.tasks.show(taskId);
  dispatch(store.actions.setTask(task));
};

export const fetchTaskResult = (taskId) => async (dispatch) => {
  const { data: result } = await LunaAdminClient.tasks.showResultsById(taskId);
  dispatch(store.actions.setResult(result));
};

export const fetchTaskSubtasks = (taskId) => async (dispatch) => {
  const { data: subtasks } = await LunaAdminClient.tasks.showSubtasksById(taskId);
  dispatch(store.actions.setSubtasks(subtasks));
};

export const fetchTaskErrors = (taskId) => async (dispatch, getState) => {
  const { task: { errors: { pageIndex, pageSize } } } = getState();
  const [
    { data: { errors } },
    { data: { count: errorCount } },
  ] = await Promise.all([
    LunaAdminClient.tasks.showErrorsById(taskId, { page: pageIndex + 1, pageSize }),
    LunaAdminClient.tasks.showErrorCountById(taskId),
  ]);
  dispatch(store.actions.setErrors({ data: errors, meta: { count: errorCount } }));
};
