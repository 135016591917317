// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/clustering.py
import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../../constants';
import { baseTask, descriptorTypeBase } from './base';
import { eventFiltersOptionaAccount, faceFiltersOptionalAccount } from './filters';

export const content = new Schema({
  objects_type: get('objects_type').asOption(ENUMS.OBJECTS_TYPE),
  threshold: get('threshold').asFloat(),
  params: {
    use_track_info: get('use_track_info').asBoolean(),
  },
  limit: get('limit').asInt(),

  ...descriptorTypeBase,
  filters: get().switch(({ objects_type }) => objects_type?.value, {
    [ENUMS.OBJECTS_TYPE.events.value]: get('filters')
      .asSchema(new Schema(faceFiltersOptionalAccount)),
    [ENUMS.OBJECTS_TYPE.faces.value]: get('filters')
      .asSchema(new Schema(eventFiltersOptionaAccount)),
  }),
});

export const clusterizationTaskSchema = new Schema({
  ...baseTask,
  content: get('content').asSchema(content),
});
