import getSchedule from '../../luna-tasks/schemas/getSchedule';
import { getTask } from '../../luna-tasks/schemas/getTask';
import postAdditionalExtractionTask from '../../luna-tasks/schemas/postAdditionalExtractionTask';
import postGarbageCollectionTask from '../../luna-tasks/schemas/postGarbageCollectionTask';
import postSchedule from '../../luna-tasks/schemas/postSchedule';
import getAccount from './getAccount';
import postAccount from './postAccount';

export async function buildSchemaProxy() {
  return {
    postAccount,
    getAccount,
    getTask,
    postAdditionalExtractionTask,
    postGarbageCollectionTask,
    postSchedule,
    getSchedule,
  };
}
