const { LUNA_TASK_ENUMS } = require('api-bindings/luna-tasks/constants');

export const makeFiltersInitialValues = ({ objects_type }) => {
  switch (objects_type.value) {
    case LUNA_TASK_ENUMS.OBJECTS_TYPE.faces.value: {
      return {
        objects_type: LUNA_TASK_ENUMS.OBJECTS_TYPE.faces,
        account_id: undefined,
        face_id__lt: undefined,
        face_id__gte: undefined,
      };
    }
    case LUNA_TASK_ENUMS.OBJECTS_TYPE.events.value: {
      return {
        objects_type: LUNA_TASK_ENUMS.OBJECTS_TYPE.events,
        account_id: undefined,
        handler_id: undefined,
        event_id__lt: undefined,
        event_id__gte: undefined,
        insert_time__lt: undefined,
        insert_time__gte: undefined,
      };
    }
    default: {
      throw new Error('Undefined objects_type');
    }
  }
};
export const makeOptionsInitialValues = ({
  extraction_target,
  descriptorVersionOptions,
}) => {
  switch (extraction_target.value) {
    case LUNA_TASK_ENUMS.EXTRACTION_TARGET.basic_attributes.value: {
      return {
        missing: true,
      };
    }
    case LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor.value: {
      return {
        descriptor_version: descriptorVersionOptions[0] || null,
        missing: true,
      };
    }
    case LUNA_TASK_ENUMS.EXTRACTION_TARGET.body_descriptor.value: {
      return {
        descriptor_version: null,
        missing: true,
      };
    }
    default: {
      throw new Error('Undefined extraction_target');
    }
  }
};

export const makeInitialValues = ({
  descriptorVersionOptions,
  objects_type,
  extraction_target,
}) => ({
  description: '',
  content: {
    extraction_target,
    filters: makeFiltersInitialValues({ objects_type }),
    options: makeOptionsInitialValues({ extraction_target, descriptorVersionOptions }),
  },
});
