import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';

export const EDITABLE_TASK_TYPES = [
  LUNA_TASK_ENUMS.TASK_TYPE[4].value,
  LUNA_TASK_ENUMS.TASK_TYPE[5].value,
];

export const EDITABLE_TASK_TYPES_OPTIONS = LUNA_TASK_ENUMS.TASK_TYPE.raw.filter(
  ({ value }) => EDITABLE_TASK_TYPES.includes(value),
);

export const SCHEDULE_TASK_TYPES = [
  LUNA_TASK_ENUMS.TASK_TYPE[1].value,
  LUNA_TASK_ENUMS.TASK_TYPE[4].value,
  LUNA_TASK_ENUMS.TASK_TYPE[5].value,
];

export const SCHEDULE_TASK_TYPES_OPTIONS = LUNA_TASK_ENUMS.TASK_TYPE.raw.filter(
  ({ value }) => SCHEDULE_TASK_TYPES.includes(value),
);
