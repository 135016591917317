import { utils } from '@vlabs/uikit/utils';

import i18n from 'translations/i18n';

export const LIVENESS_OPTIONS_STRICT = utils.createProxy([
  { value: 0, label: i18n.t('lunaApi:константы.liveness.0') },
  { value: 1, label: i18n.t('lunaApi:константы.liveness.1') },
  { value: 2, label: i18n.t('lunaApi:константы.liveness.2') },
]);

export const LIVENESS_OPTIONS = utils.createProxy([
  ...LIVENESS_OPTIONS_STRICT.raw,
  { value: 'null', label: i18n.t('constants:оценка не проводилась') },
]);

export const EVENT_ENUMS = {
  BODY_BASIC_ATTRIBUTES: {
    APPARENT_GENDER: utils.createProxy([
      { value: 0, label: i18n.t('lunaApi:константы.event.body_basic_attributes.apparent_gender.0') },
      { value: 1, label: i18n.t('lunaApi:константы.event.body_basic_attributes.apparent_gender.1') },
      { value: 2, label: i18n.t('lunaApi:константы.event.body_basic_attributes.apparent_gender.2') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
  },
  UPPER_BODY: {
    HEADWEAR: utils.createProxy([
      { value: 0, label: i18n.t('lunaApi:константы.event.upper_body.headwear.0') },
      { value: 1, label: i18n.t('lunaApi:константы.event.upper_body.headwear.1') },
      { value: 2, label: i18n.t('lunaApi:константы.event.upper_body.headwear.2') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
    HEADWEAR_COLORS: utils.createProxy([
      { value: 'undefined', label: i18n.t('lunaApi:константы.event.upper_body.headwear.colors.undefined') },
      { value: 'black', label: i18n.t('lunaApi:константы.event.upper_body.headwear.colors.black') },
      { value: 'white', label: i18n.t('lunaApi:константы.event.upper_body.headwear.colors.white') },
      { value: 'other', label: i18n.t('lunaApi:константы.event.upper_body.headwear.colors.other') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
    SLEEVE: utils.createProxy([
      { value: 'short', label: i18n.t('lunaApi:константы.event.upper_body.sleeve.short') },
      { value: 'long', label: i18n.t('lunaApi:константы.event.upper_body.sleeve.long') },
      { value: 'undefined', label: i18n.t('lunaApi:константы.event.upper_body.sleeve.undefined') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
    UPPER_CLOSING_COLOR: utils.createProxy([
      { value: 'undefined', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.undefined') },
      { value: 'black', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.black') },
      { value: 'blue', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.blue') },
      { value: 'green', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.green') },
      { value: 'gray', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.gray') },
      { value: 'orange', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.orange') },
      { value: 'purple', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.purple') },
      { value: 'red', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.red') },
      { value: 'white', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.white') },
      { value: 'yellow', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.yellow') },
      { value: 'pink', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.pink') },
      { value: 'brown', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.brown') },
      { value: 'beige', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.beige') },
      { value: 'khaki', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.khaki') },
      { value: 'multicolored', label: i18n.t('lunaApi:константы.event.upper_body.upper_closing_color.multicolored') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
  },
  LOWER_BODY: {
    LOWER_GARMENT: {
      TYPE: utils.createProxy([
        { value: 'undefined', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.type.undefined') },
        { value: 'trousers', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.type.trousers') },
        { value: 'shorts', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.type.shorts') },
        { value: 'skirt', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.type.skirt') },
        { value: 'null', label: i18n.t('constants:оценка не проводилась') },
      ]),
      COLORS: utils.createProxy([
        { value: 'undefined', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.undefined') },
        { value: 'black', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.black') },
        { value: 'blue', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.blue') },
        { value: 'green', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.green') },
        { value: 'gray', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.gray') },
        { value: 'orange', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.orange') },
        { value: 'purple', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.purple') },
        { value: 'red', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.red') },
        { value: 'white', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.white') },
        { value: 'yellow', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.yellow') },
        { value: 'pink', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.pink') },
        { value: 'brown', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.brown') },
        { value: 'beige', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.beige') },
        { value: 'khaki', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.khaki') },
        { value: 'multicolored', label: i18n.t('lunaApi:константы.event.lower_body.lower_garment.colors.multicolored') },
        { value: 'null', label: i18n.t('constants:оценка не проводилась') },
      ]),
    },
    SHOES: {
      APPARENT_COLOR: utils.createProxy([
        { value: 'undefined', label: i18n.t('lunaApi:константы.event.lower_body.shoes.apparent_color.undefined') },
        { value: 'black', label: i18n.t('lunaApi:константы.event.lower_body.shoes.apparent_color.black') },
        { value: 'white', label: i18n.t('lunaApi:константы.event.lower_body.shoes.apparent_color.white') },
        { value: 'other', label: i18n.t('lunaApi:константы.event.lower_body.shoes.apparent_color.other') },
        { value: 'null', label: i18n.t('constants:оценка не проводилась') },
      ]),
    },
  },
  ACCESSORIES: {
    BACKPACK: utils.createProxy([
      { value: 0, label: i18n.t('lunaApi:константы.event.accessories.backpack.0') },
      { value: 1, label: i18n.t('lunaApi:константы.event.accessories.backpack.1') },
      { value: 2, label: i18n.t('lunaApi:константы.event.accessories.backpack.2') },
      { value: 'null', label: i18n.t('constants:оценка не проводилась') },
    ]),
  },
};

export const TASKS = {
  TASKS_TYPE: utils.createProxy([
    { label: 'unknown', value: 0 },
    { label: 'linker', value: 1 },
    { label: 'clusterization', value: 2 },
    { label: 'report', value: 3 },
    { label: 'garbage collect', value: 4 },
    { label: 'additional extract', value: 5 },
    { label: 'cross match', value: 6 },
    { label: 'roc-curve calculation', value: 7 },
    { label: 'export objects', value: 8 },
    { label: 'estimate data', value: 9 },
  ]),
  ESTIMATOR: {
    SOURCE: {
      SOURCE_TYPE: utils.createProxy([
        { label: i18n.t('lunaApi:estimator task.source.source_type.zip'), value: 'zip' },
        { label: i18n.t('lunaApi:estimator task.source.source_type.samba'), value: 'samba' },
        { label: i18n.t('lunaApi:estimator task.source.source_type.ftp'), value: 'ftp' },
        { label: i18n.t('lunaApi:estimator task.source.source_type.network_disk'), value: 'network_disk' },
        { label: i18n.t('lunaApi:estimator task.source.source_type.s3'), value: 's3' },
      ]),
      IMAGE_TYPE: utils.createProxy([
        { label: i18n.t('lunaApi:estimator task.source.image_type.0'), value: '0' },
        { label: i18n.t('lunaApi:estimator task.source.image_type.1'), value: '1' },
        { label: i18n.t('lunaApi:estimator task.source.image_type.2'), value: '2' },
      ]),
      SIGNATURE_VERSION: utils.createProxy([
        { label: i18n.t('lunaApi:estimator task.source.signature_version.s3v2'), value: 's3v2' },
        { label: i18n.t('lunaApi:estimator task.source.signature_version.s3v4'), value: 's3v4' },
      ]),
    },
  },
};
