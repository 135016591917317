import React, { useEffect, useMemo } from 'react';

import { Table, Panel, TableCells } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OptionCell from 'components/option-cell/OptionCell';
import ProgressCell from 'components/progress-cell/ProgressCell';

function TaskInfo({
  fetchTaskInfo,
  data,
}) {
  const { t } = useTranslation();
  useEffect(() => { fetchTaskInfo(); }, [fetchTaskInfo]);

  const columns = useMemo(() => ([
    { Header: t('tasks:таблица.колонки.task_id'), accessor: 'task_id', width: 85 },
    { Header: t('tasks:таблица.колонки.task_type'), accessor: 'task_type', Cell: OptionCell },
    { Header: t('tasks:таблица.колонки.create_time'), accessor: 'create_time', Cell: TableCells.DateCell },
    { Header: t('tasks:таблица.колонки.last_update_time'), accessor: 'last_update_time', Cell: TableCells.DateCell },
    { Header: t('tasks:таблица.колонки.end_time'), accessor: 'end_time', Cell: TableCells.DateCell },
    { Header: t('tasks:таблица.колонки.count_task_parts_done'), accessor: 'count_task_parts_done', width: 100 },
    { Header: t('tasks:таблица.колонки.count_task_parts_all'), accessor: 'count_task_parts_all', width: 100 },
    { Header: t('tasks:таблица.колонки.description'), accessor: 'description' },
    { Header: t('tasks:таблица.колонки.task_status'), accessor: 'task_status', Cell: ProgressCell, width: 100 },
  ]), [t]);

  return (
    <Panel>
      <Table data={data} columns={columns} />
    </Panel>
  );
}

TaskInfo.propTypes = {
  fetchTaskInfo: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TaskInfo;
