import { get, CleanSchema } from 'api-bindings/json-transform';

import { ACCOUNT_TYPE } from '../constants';

export default new CleanSchema({
  account_type: get('account_type').asOption(ACCOUNT_TYPE),
  login: get('login').asString(),
  description: get('description').asString(),
  account_id: get('account_id').asString(),
});
