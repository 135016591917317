import { cloneDeep, get, isEqual, set } from 'lodash';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import {
  makeFiltersInitialValues as makeAEFiltersInitialValues,
  makeOptionsInitialValues as makeAEOptionsInitialValues,
} from 'features/task-list/additionalExtractionInitialValues';

export const makeDefaultValues = ({
  isLunaEventsAvailable,
  task_type,
  descriptorVersionOptions,
}) => {
  switch (task_type?.value) {
    case LUNA_TASK_ENUMS.TASK_TYPE[4].value: {
      return {
        task: {
          task_type,
          content: {
            target: isLunaEventsAvailable ? LUNA_TASK_ENUMS.TARGET.events : LUNA_TASK_ENUMS.TARGET.faces,
            filters: {},
            store_results: true,
          },
        },
        trigger: {
          cron: '',
          cron_timezone: LUNA_TASK_ENUMS.SCHEDULE_CRON_TIMEZONE.local,
        },
        behaviour: {
          start_immediately: false,
          create_stopped: false,
        },
      };
    }
    case LUNA_TASK_ENUMS.TASK_TYPE[5].value: {
      return {
        task: {
          task_type,
          content: {
            extraction_target: LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor,
            filters: makeAEFiltersInitialValues({
              objects_type: isLunaEventsAvailable
                ? LUNA_TASK_ENUMS.OBJECTS_TYPE.events
                : LUNA_TASK_ENUMS.OBJECTS_TYPE.faces,
            }),
            options: makeAEOptionsInitialValues({
              descriptorVersionOptions,
              extraction_target: LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor,
            }),
          },
        },
        trigger: {
          cron: '',
          cron_timezone: LUNA_TASK_ENUMS.SCHEDULE_CRON_TIMEZONE.local,
        },
        behaviour: {
          start_immediately: false,
          create_stopped: false,
        },
      };
    }
    default: {
      return {};
    }
  }
};

export const buildValueCleaner = ({
  LISAvailability,
  isLunaEventsAvailable,
}) => (values) => {
  const cleanedValues = cloneDeep(values);
  const cleanedFields = [];

  // GC Task
  if (isEqual(get(values, 'task.task_type'), LUNA_TASK_ENUMS.TASK_TYPE[4])) {
    if (!LISAvailability.taskResults) {
      if (get(values, 'task.content.store_results') === true) {
        set(values, 'task.content.store_results', false);
        cleanedFields.push('task.content.store_results');
      }
    }
    if (!isLunaEventsAvailable) {
      if (get(values, 'task.content.target.value') === LUNA_TASK_ENUMS.TARGET.events.value) {
        set(values, 'task.content.target', LUNA_TASK_ENUMS.TARGET.faces);
        cleanedFields.push('task.content.target');
      }
    }
  }
  return {
    values: cleanedValues,
    cleanedFields,
  };
};
