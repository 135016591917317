import { subDays } from 'date-fns';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';

export const eventsInitialValues = (LISAvailability) => ({
  target: LUNA_TASK_ENUMS.TARGET.events,
  filters: {
    account_id: undefined,
    create_time__lt: [subDays(new Date(), 1)],
    insert_time__lt: undefined,
  },
  remove_samples: false,
  store_results: LISAvailability.taskResults,
});

export const eventDescriptorsInitialValues = (descriptorVersions) => ({
  target: LUNA_TASK_ENUMS.TARGET.event_descriptors,
  filters: {
    descriptor_version: descriptorVersions[0],
    descriptor_type: LUNA_TASK_ENUMS.DESCRIPTOR_TYPE.face,
  },
});

export const faceDescriptorsInitialValues = (descriptorVersions) => ({
  target: LUNA_TASK_ENUMS.TARGET.face_descriptors,
  filters: {
    descriptor_version: descriptorVersions[0],
  },
});

export const facesInitialValues = (LISAvailability) => ({
  target: LUNA_TASK_ENUMS.TARGET.faces,
  remove_samples: false,
  store_results: LISAvailability.taskResults,
  filters: {
    account_id: undefined,
    create_time__lt: undefined,
    create_time__gte: undefined,
    list_id: undefined,
    user_data: undefined,
  },
});
