import React from 'react';

import {
  PauseCircleIcon, CircleCheckIcon, CircleXIcon, CircleMinusIcon, ShoppingBagIcon, LoaderIcon,
} from '@vlabs/icons';
import PropTypes from 'prop-types';

import './ProgressCell.sass';

export default function ProgressCell({ value }) {
  const iconsMap = {
    0: <PauseCircleIcon className="ProgressCell__Label_pending" />,
    1: <LoaderIcon className="ProgressCell__Label_neutral" />,
    2: <CircleMinusIcon className="ProgressCell__Label_canceled" />,
    3: <CircleXIcon className="ProgressCell__Label_interrupted" />,
    4: <ShoppingBagIcon className="ProgressCell__Label_neutral" />,
    5: <CircleCheckIcon className="ProgressCell__Label_finished" />,
  };

  return (
    <div className="ProgressCell" title={value.label}>
      {iconsMap[value.value]}
    </div>
  );
}

ProgressCell.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }).isRequired,
};
