import { loadProgressBar } from '@vlabs/axios-progress-bar';

import '@vlabs/axios-progress-bar/dist/nprogress.css';
import { BaseLunaClient } from 'api-bindings/BaseLunaClient';

import { buildSchemaProxy } from './schemas';

class LunaAdmin extends BaseLunaClient {
  constructor(baseUrl = '') {
    super({
      apiVersion: 4,
      baseUrl,
      afterInit: () => buildSchemaProxy(this)
        .then((result) => {
          this.shs = result;
        }),
    });
    loadProgressBar('', this.http);
  }

  get views() {
    return {
      main: () => this.http.get('/'),
      viewVersion: () => this.http.get('/views'),
      logIn: () => this.http.get('/login'),
      getAccountInfoTables: () => this.http.get('/accounts'),
      getAccountInfoById: (id) => this.http.get(`/accounts/${id}`),
      getAccountLists: (id) => this.http.get(`/accounts/${id}/lists`),
      getTaskInfoTables: () => this.http.get('/tasks'),
    };
  }

  get help() {
    return {
      getVersion: async () => {
        const { data: { Version } } = await this.http.get('/version', { baseURL: this.baseURL });
        return Version;
      },
      getSystemInfo: () => this.http.get('/luna_sys_info'),
    };
  }

  get auth() {
    return {
      check: async () => this.http.get('/login', {
        headers: {
          'Exclude-Header': 'WWW-Authenticate',
        },
      }),
      login: async (username, password) => this.http.post('/login', null, {
        auth: { username, password },
        headers: {
          'Exclude-Header': 'WWW-Authenticate',
        },
      }),
      logout: async () => this.http.delete('/login', null, {
        auth: { username: 'ignore', password: 'ignore' },
      }),
    };
  }

  get search() {
    const prefix = 'search';
    return {
      searchElementByIdOrEmail: async (params) => this.http.get(`/${prefix}`, { params }),
    };
  }

  get accounts() {
    const prefix = 'accounts';
    return {
      showAll: async (params) => {
        const res = await this.http.get(`/${prefix}`, { params });
        if (res?.data?.accounts) {
          res.data.accounts = res.data.accounts.map(this.shs.getAccount);
        }
        return res;
      },
      create: (data) => this.http.post(`/${prefix}`, this.shs.postAccount(data)),
      replace: (accountId, data) => this.http.put(`/${prefix}/${accountId}`, this.shs.postAccount(data)),
      createById: (id, data) => this.http.put(`/${prefix}/${id}`, data),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.put(`/${prefix}/${id}`, data),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
    };
  }

  get lists() {
    const prefix = 'lists';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      show: (id) => this.http.get(`/${prefix}/${id}`),
    };
  }

  get tasks() {
    const prefix = 'tasks';
    return {
      showAll: async (params) => {
        const res = await this.http.get(`/${prefix}`, { params });
        return {
          ...res,
          data: {
            ...res.data,
            tasks: res.data.tasks.map(this.shs.getTask),
          },
        };
      },
      getCount: (params) => this.http.get(`/${prefix}/count`, { params }),
      show: async (id) => {
        const result = await this.http.get(`/${prefix}/${id}`);
        if (result?.data) result.data = this.shs.getTask(result.data);
        return result;
      },
      cancel: (id) => this.http.patch(`/${prefix}/${id}`),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      showResultsById: (id) => this.http.get(`/${prefix}/${id}/result`),
      showErrorsById: (id, params) => this.http.get(`/${prefix}/${id}/errors`, { params }),
      showErrorCountById: (id, params) => this.http.get(`/${prefix}/${id}/errors/count`, { params }),
      showSubtasksById: (id) => this.http.get(`/${prefix}/${id}/subtasks`),
      showErrors: (params) => this.http.get(`/${prefix}/errors`, { params }),
      showErrorCount: (params) => this.http.get(`/${prefix}/errors/count`, { params }),
      showErrorById: (id) => this.http.get(`/${prefix}/errors/${id}`),
      createGCTask: (data) => this.http.post(`/${prefix}/gc`, this.shs.postGarbageCollectionTask(data)),
      createAdditionalExtractionTask: (data) => this.http.post(`/${prefix}/additional_extract`, this.shs.postAdditionalExtractionTask(data)),
    };
  }

  get schedules() {
    return {
      create: (data) => this.http.post('/tasks/schedules', this.shs.postSchedule(data)),
      getAll: async (params) => {
        const res = await this.http.get('/tasks/schedules', { params });
        return {
          ...res,
          data: res.data.map(this.shs.getSchedule),
        };
      },
      get: async (scheduleId) => {
        const res = await this.http.get(`/tasks/schedules/${scheduleId}`);
        return {
          ...res,
          data: this.shs.getSchedule(res.data),
        };
      },
      replace: (scheduleId, data) => this.http.put(`/tasks/schedules/${scheduleId}`, this.shs.postSchedule(data)),
      patch: (scheduleId, action) => this.http.patch(`/tasks/schedules/${scheduleId}`, null, {
        params: {
          action,
        },
      }),
      start: (scheduleId) => this.schedules.patch(scheduleId, 'start'),
      pause: (scheduleId) => this.schedules.patch(scheduleId, 'pause'),
      delete: (scheduleId) => this.http.delete(`/tasks/schedules/${scheduleId}`),
    };
  }
}

export { LunaAdmin };
