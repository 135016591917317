import React from 'react';

import { ArrowCircleRightIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import st from './GoToCell.module.sass';

const GoToCell = ({
  value,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (value === undefined) return null;
  const onClick = () => { history.push(value); };

  return (
    <div className={st.GoToCellWrapper} title={t('иконки.goto')}>
      <ArrowCircleRightIcon
        data-testid="GoToCell"
        className={st.Icon}
        onClick={onClick}
      />
    </div>
  );
};

GoToCell.propTypes = {
  value: PropTypes.string,
};

GoToCell.defaultProps = {
  value: '',
};

export default GoToCell;
