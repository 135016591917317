import { createSelector } from '@reduxjs/toolkit';

export const selectAccountSlice = (state) => state.account;

export const selectAccountInfo = createSelector(
  selectAccountSlice,
  ({ account }) => account,
);

export const selectAccountLists = createSelector(
  selectAccountSlice,
  ({ lists }) => lists,
);
