import { createSelector } from '@reduxjs/toolkit';

export const selectAppSlice = (state) => state.app;

export const selectAppState = createSelector(
  selectAppSlice,
  ({ state }) => state,
);

export const selectAppServices = createSelector(
  selectAppSlice,
  ({ services }) => services,
);
