import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { selectAppState } from 'features/app/selectors';

import { LunaError } from 'api-bindings/luna-client/LunaError';
import { LunaAdminClient } from 'api/index';
import i18n from 'translations/i18n';

const initialState = {
  state: undefined,
  accounts: {
    data: [],
    meta: { count: 0 },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

const store = createSlice({
  name: 'accountList',
  initialState,
  reducers: {
    setAccounts(state, { payload }) {
      state.accounts.data = payload.data;
      state.accounts.meta = payload.meta;
      state.state = 'loaded';
    },
    setPageIndex(state, { payload }) {
      state.accounts.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      state.accounts.pageSize = payload;
    },
    resetStore() { return initialState; },
  },
});

export default store.reducer;

export const { resetStore, setPageIndex, setPageSize } = store.actions;

export const fetchAccounts = async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;

  const { accountList: { accounts: { pageIndex, pageSize } } } = getState();

  try {
    const { data: {
      accounts: data,
      account_count: count,
    } } = await LunaAdminClient.accounts.showAll({ page: pageIndex + 1, page_size: pageSize });
    dispatch(store.actions.setAccounts({ data, meta: { count } }));
  } catch (error) {
    dispatch(store.actions.setAccounts({ data: [] }));
    throw error;
  }
};

export const createAccount = (account) => async (dispatch) => {
  const { account_id, ...rest } = account;
  try {
    if (account_id) await LunaAdminClient.accounts.replace(account_id, rest);
    if (!account_id) await LunaAdminClient.accounts.create(rest);
    dispatch(fetchAccounts);
    toast.success(i18n.t('accounts:api.создание.успех', { account: rest }));
  } catch (error) {
    if (error instanceof LunaError) {
      if (error.error_code === 41001) {
        const errorMessage = i18n.t('accounts:api.обновление данных.валидация.неуникальный email', { account });
        return { login: errorMessage };
      }
      if (error.error_code === 41002) {
        const errorMessage = i18n.t('accounts:api.обновление данных.валидация.неуникальный account_id', { account });
        return { account_id: errorMessage };
      }
    }
    throw error;
  }

  return undefined;
};

export const deleteAccount = (account) => async (dispatch) => {
  await LunaAdminClient.accounts.delete(account.account_id);
  toast.success(i18n.t('accounts:api.удаление.успех', { account }));
  dispatch(fetchAccounts);
};
