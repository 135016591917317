import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../constants';

const faceFiltersSchema = new Schema({
  objects_type: ENUMS.OBJECTS_TYPE.faces.value,
  account_id: get('account_id').ifEmptyString(undefined),
  face_id__lt: get('face_id__lt').ifEmptyString(undefined),
  face_id__gte: get('face_id__gte').ifEmptyString(undefined),
});

const eventFiltersSchema = new Schema({
  objects_type: ENUMS.OBJECTS_TYPE.events.value,
  account_id: get('account_id').ifEmptyString(undefined),
  handler_id: get('handler_id').ifEmptyString(undefined),
  event_id__gte: get('event_id__gte').ifEmptyString(undefined),
  event_id__lt: get('event_id__lt').ifEmptyString(undefined),
  insert_time__gte: get('insert_time__gte.0').asISOString(),
  insert_time__lt: get('insert_time__lt.1').asISOString(),
});

const filtersSchema = (filters) => {
  if (filters.objects_type.value === ENUMS.OBJECTS_TYPE.events.value) return eventFiltersSchema(filters);
  if (filters.objects_type.value === ENUMS.OBJECTS_TYPE.faces.value) return faceFiltersSchema(filters);
  throw Error(`unknown object_type: ${filters.objects_type.value}`);
};

const basicAttributesSchema = new Schema({
  extraction_target: ENUMS.EXTRACTION_TARGET.basic_attributes.value,
  options: {
    missing: get('options.missing').asBoolean(),
  },
  filters: get('filters').as(filtersSchema),
});

const faceDescriptorSchema = new Schema({
  extraction_target: ENUMS.EXTRACTION_TARGET.face_descriptor.value,
  options: {
    descriptor_version: get('options.descriptor_version.value').asInt(),
    missing: get('options.missing').asBoolean(),
  },
  filters: get('filters').as(filtersSchema),
});

const bodyDescriptorSchema = new Schema({
  extraction_target: ENUMS.EXTRACTION_TARGET.body_descriptor.value,
  options: {
    descriptor_version: get('options.descriptor_version.value').asInt(),
    missing: get('options.missing').asBoolean(),
  },
  filters: get('filters').asSchema(eventFiltersSchema),
});

export default new Schema({
  description: get('description').ifEmptyString(undefined),
  content: get('content').as((content) => {
    switch (content.extraction_target.value) {
      case ENUMS.EXTRACTION_TARGET.body_descriptor.value:
        return bodyDescriptorSchema(content);
      case ENUMS.EXTRACTION_TARGET.face_descriptor.value:
        return faceDescriptorSchema(content);
      case ENUMS.EXTRACTION_TARGET.basic_attributes.value:
        return basicAttributesSchema(content);
      default:
        throw Error(`${content.extraction_target.value} extraction target does not exist`);
    }
  }),
});
