import React, { useCallback, useMemo, useState } from 'react';

import { Control, Divider, openConfirmPopup, Page, Panel, Table, TableCells, TableFilters, useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { selectServiceAvailability } from 'features/info/selectors';

import { LunaAdminClient } from 'api';
import { LunaError } from 'api-bindings/luna-client/LunaError';
import { useAsyncTableData } from 'utils/useAsyncTableData';

import { PauseCell, StartCell } from './components/cells';
import { EditScheduleForm } from './EditScheduleForm';
import { EDITABLE_TASK_TYPES, SCHEDULE_TASK_TYPES_OPTIONS } from './settings';
import { hydrateFormFrom as hydrateScheduleFormFrom } from './utils';

const ScheduleListPageComponent = ({
  isLunaTasksAvailable,
}) => {
  const { t } = useTranslation();
  const [selectedSchedule, setSelectedSchedule] = useState(undefined);

  const editFormModal = useModal();

  const tableData = useAsyncTableData(useCallback(async ({ page, page_size, ...params }) => {
    try {
      const filters = Object.values(params).reduce((acc, { id, value }) => {
        // eslint-disable-next-line default-case
        switch (id) {
          case 'task_type': {
            acc[id] = value.value;
            break;
          }
        }

        return acc;
      }, {});
      const { data } = await LunaAdminClient.schedules.getAll({ page, page_size, ...filters });
      return data;
    } catch (error) {
      if (error instanceof LunaError) {
        if (error.error_code === 11039) {
          toast.error(t('api:ошибка.сообщение', { error: 'Luna Tasks service is required for tasks scheduling.' }), { toastId: error.error_code });
          return [];
        }
        if (error.error_code === 28021) {
          toast.error(t('api:ошибка.сообщение', { error: 'Luna Events service is required for task scheduling of events related data' }), { toastId: error.error_code });
          return [];
        }
      }
      throw error;
    }
  }, [t]));

  const startScheduleHandler = useCallback(async (scheduleId) => {
    await LunaAdminClient.schedules.start(scheduleId);
    await tableData.fetch();
  }, [tableData.fetch]);

  const pauseScheduleHandler = useCallback(async (scheduleId) => {
    await LunaAdminClient.schedules.pause(scheduleId);
    await tableData.fetch();
  }, [tableData.fetch]);

  const onSubmitScheduleFormHandler = useCallback(async (data) => {
    if (selectedSchedule?.raw?.schedule_id !== undefined) {
      await LunaAdminClient.schedules.replace(selectedSchedule.raw.schedule_id, data);
      toast.success(t('schedules:actions.update.success', { schedule: selectedSchedule.raw }));
    } else {
      await LunaAdminClient.schedules.create(data);
      toast.success(t('schedules:actions.create.success', { schedule: data }));
    }
    await tableData.fetch();
    editFormModal.close();
  }, [selectedSchedule, tableData.fetch]);

  const taskEditHandler = useCallback((values) => {
    setSelectedSchedule({
      raw: values,
      formValues: hydrateScheduleFormFrom(values),
    });
    editFormModal.open();
  }, []);

  const taskCreateHandler = useCallback(() => {
    setSelectedSchedule(undefined);
    editFormModal.open();
  }, []);

  const taskDeleteHandler = useCallback(async (schedule) => {
    openConfirmPopup({
      title: t('schedules:actions.delete.confirmation.title'),
      message: t('schedules:actions.delete.confirmation.message', { schedule }),
      type: 'delete',
      onConfirm: async () => {
        await LunaAdminClient.schedules.delete(schedule.schedule_id);
        toast.info(t('schedules:actions.delete.success', { schedule }));
        await tableData.fetch();
      },
    });
  }, [tableData.fetch]);

  const columns = useMemo(() => ([
    { id: 'actionStart',
      type: 'action',
      Cell: StartCell,
      accessor: (row) => ({
        objectId: row?.schedule_id,
        onClick: startScheduleHandler,
      }) },
    { id: 'actionPause',
      type: 'action',
      Cell: PauseCell,
      accessor: (row) => ({
        objectId: row?.schedule_id,
        onClick: pauseScheduleHandler,
      }) },
    { Header: t('schedules:table.columns.status'), accessor: 'status.label', width: 90 },
    { Header: t('schedules:table.columns.schedule_id'), accessor: 'schedule_id', width: 70 },
    { Header: t('schedules:table.columns.task.task_type'),
      accessor: 'task.task_type.label',
      id: 'task_type',
      Filter: TableFilters.OneOfFilter({ options: SCHEDULE_TASK_TYPES_OPTIONS }) },
    { Header: t('schedules:table.columns.trigger.cron'), accessor: 'trigger.cron' },
    { Header: t('schedules:table.columns.trigger.cron_timezone'), accessor: 'trigger.cron_timezone.label', width: 120 },
    { Header: t('schedules:table.columns.next_run_time'), accessor: 'next_run_time', Cell: TableCells.DateCell },
    { Header: t('schedules:table.columns.create_time'), accessor: 'create_time', Cell: TableCells.DateCell },
    { Header: t('schedules:table.columns.last_update_time'), accessor: 'last_update_time', Cell: TableCells.DateCell },
    { id: 'actionEdit',
      type: 'action',
      Cell: (cell) => {
        if (EDITABLE_TASK_TYPES.includes(cell.row.original.task.task_type?.value)) return TableCells.EditCell(cell);
        return null;
      } },
    { id: 'actionDelete', type: 'action', Cell: TableCells.DeleteCell },
  ]), [t, startScheduleHandler, pauseScheduleHandler]);

  return (
    <Page actions={(
      <Control.Button
        onClick={taskCreateHandler}
        disabled={!isLunaTasksAvailable}
        data-testid="schedules.add"
      >
        {t('schedules:table.add')}
      </Control.Button>
    )}
    >
      {editFormModal.wrap(
        <EditScheduleForm
          values={selectedSchedule?.formValues}
          context={selectedSchedule?.raw?.schedule_id === undefined ? 'create' : 'update'}
          onSubmit={onSubmitScheduleFormHandler}
        />,
      )}
      <Divider small />
      <Panel>
        <Table
          {...tableData}
          pageSizeOptions={[10, 25, 50, 100]}
          columns={columns}
          paginationType="offsetBased"
          onActions={{
            // onAddRow: { handler: taskCreateHandler },
            onEditRow: { handler: taskEditHandler },
            onDeleteRow: { handler: taskDeleteHandler },
          }}
        />
      </Panel>
    </Page>
  );
};

ScheduleListPageComponent.propTypes = {
  isLunaTasksAvailable: PropTypes.bool,
};

ScheduleListPageComponent.defaultProps = {
  isLunaTasksAvailable: false,
};

export const ScheduleListPage = connect((state) => ({
  isLunaTasksAvailable: selectServiceAvailability(state, 'luna-tasks'),
}))(ScheduleListPageComponent);
