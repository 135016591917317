import { utils } from '@vlabs/uikit/utils';

import i18n from 'translations/i18n';

export const ACCOUNT_TYPE = utils.createProxy([
  { value: 'user', label: i18n.t('lunaAdmin:константы.account_type.user') },
  { value: 'advanced_user', label: i18n.t('lunaAdmin:константы.account_type.advanced_user') },
  { value: 'admin', label: i18n.t('lunaAdmin:константы.account_type.admin') },
]);

export const LUNA_ADMIN_ENUMS = {
  ACCOUNT_TYPE,
};
