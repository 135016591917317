import { get, Schema } from 'api-bindings/json-transform';
import { content as getAdditionalExtractContentSchema } from 'api-bindings/luna-tasks/schemas/get/additionalExtract';
import { content as getGCContentSchema } from 'api-bindings/luna-tasks/schemas/get/gc';
import { content as getLinkerContentSchema } from 'api-bindings/luna-tasks/schemas/get/linker';

import { LUNA_TASK_ENUMS as ENUMS } from '../constants';

export default new Schema({
  schedule_id: get('schedule_id').asInt(),
  task_ids: get('task_ids').asArrayOfInt(),
  task: {
    task_type: get('task.task_type').asOption(ENUMS.TASK_TYPE),
    content: get('task').switch((task) => task.task_type, {
      [ENUMS.TASK_TYPE[1].value]: ({ content }) => getLinkerContentSchema(content),
      [ENUMS.TASK_TYPE[4].value]: ({ content }) => getGCContentSchema(content),
      [ENUMS.TASK_TYPE[5].value]: ({ content }) => getAdditionalExtractContentSchema(content),
      default: (v) => v,
    }),
  },
  trigger: {
    cron: get('trigger.cron').asString(),
    cron_timezone: get('trigger.cron_timezone').asOption(ENUMS.SCHEDULE_CRON_TIMEZONE),
  },
  status: get('status').asOption(ENUMS.SCHEDULE_STATUS),
  next_run_time: get('next_run_time').asDate(),
  create_time: get('create_time').asDate(),
  last_update_time: get('last_update_time').asDate(),
});
