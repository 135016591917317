import React, { useMemo } from 'react';

import { Table, Panel, TableCells } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TaskErrors(props) {
  const { t } = useTranslation();

  const columns = useMemo(() => ([
    { Header: t('tasks:ошибки.таблица.колонки.error_id'), accessor: 'error_id' },
    { Header: t('tasks:ошибки.таблица.колонки.error_time'), accessor: 'error_time', Cell: TableCells.DateCell },
    { Header: t('tasks:ошибки.таблица.колонки.error_code'), accessor: 'error_code' },
    { Header: t('tasks:ошибки.таблица.колонки.description'), accessor: 'description' },
    { Header: t('tasks:ошибки.таблица.колонки.detail'), accessor: 'detail' },
    { Header: t('tasks:ошибки.таблица.колонки.additional_info'), accessor: 'additional_info' },
  ]), [t]);
  return (
    <Panel>
      <Table
        {...props}
        columns={columns}
        paginationType="offsetBased"
      />
    </Panel>
  );
}

TaskErrors.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    count: PropTypes.number,
  }),
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
};

TaskErrors.defaultProps = {
  data: [],
  meta: { count: 0 },
  pageIndex: 0,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
};

export default TaskErrors;
