import React from 'react';

import { DownloadIcon } from '@vlabs/icons';
import { Page, Panel, Control, Grid, GridRow, GridCol } from '@vlabs/uikit';
import cn from 'classnames';
import { isValid, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import st from './InfoPage.module.sass';
import { selectLicenseInfo, selectSysInfo, selectVersion } from './selectors';
import { getSystemResult } from './store';

const InfoPage = ({ version, licenseInfo, sysInfo }) => {
  const { expiration_time, faces_limit, liveness } = licenseInfo;
  let expirationTimeValue = parseISO(expiration_time?.value);
  if (isValid(expirationTimeValue)) {
    expirationTimeValue = expirationTimeValue.toLocaleString();
  } else {
    expirationTimeValue = expiration_time?.value;
  }
  const { current_faces_attributes_count } = sysInfo;
  // eslint-disable-next-line camelcase, no-unsafe-optional-chaining
  let dbFullness = Math.round((current_faces_attributes_count / faces_limit?.value) * 100);
  if (Number.isNaN(dbFullness)) dbFullness = undefined;
  else dbFullness += ' %';

  const format = (value) => {
    if (typeof value === 'boolean') {
      if (value === true) {
        return 'available';
      }
      return '—';
    }

    return value;
  };

  return (
    <Page>
      <Grid>
        <GridRow>

          <GridCol>
            <Panel className={cn(st.Panel, st.Text)}>
              <h6>The Admin service is used to perform general administrative routines</h6>
              <ul>
                <li>Manage user accounts</li>
                <li>Receive information about the existing Lists</li>
                <li>Create garbage collection tasks</li>
                <li>Create tasks to extract descriptors with a new neural network version</li>
                <li>Receive reports and errors on processed tasks</li>
                <li>Cancel and delete existing tasks</li>
              </ul>
              <p>Admin service has access to all the data attached to different accounts.</p>
              <div
                className={st.Footer}
                title="Use the button to receive technical information about LUNA PLATFORM.
                This information is required for our technical support.
                When you send us an issue, please attach the received JSON file to your letter."
              >
                <Control.Button
                  onClick={getSystemResult}
                  icon={<DownloadIcon />}
                >
                  Download system info
                </Control.Button>
              </div>
            </Panel>
          </GridCol>

          <GridCol>
            <Panel>
              <div className={st.Panel}>
                <h6>License info</h6>
                <dl className={st.LicenseInfo}>
                  <div>
                    <dt>Availability</dt>
                    <dd>{format(expiration_time?.is_available)}</dd>
                  </div>
                  <div>
                    <dt>Expiration time</dt>
                    <dd>{format(expirationTimeValue)}</dd>
                  </div>
                  <div>
                    <dt>Luna-faces database size limit not exceeded</dt>
                    <dd>{format(faces_limit?.is_available)}</dd>
                  </div>
                  <div>
                    <dt>
                      Luna-faces database size limit
                      (maximum number of faces with attached attributes)
                    </dt>
                    <dd>{format(faces_limit?.value)}</dd>
                  </div>
                  <div>
                    <dt>
                      Database fullness percent
                    </dt>
                    <dd>
                      {format(dbFullness)}
                    </dd>
                  </div>
                  <div>
                    <dt>Liveness availability</dt>
                    <dd>{format(liveness?.is_available ?? false)}</dd>
                  </div>
                  <div>
                    <dt>Liveness connected</dt>
                    <dd>{format(liveness?.value)}</dd>
                  </div>
                  <div>
                    <dt>Liveness transactions left</dt>
                    <dd>{format(licenseInfo?.liveness_balance?.value ?? 'inf')}</dd>
                  </div>

                  <div>
                    <dt>Streams availability</dt>
                    <dd>{format(licenseInfo?.streams_limit?.is_available ?? false)}</dd>
                  </div>
                  <div>
                    <dt>Streams limit</dt>
                    <dd>{format(licenseInfo?.streams_limit?.value)}</dd>
                  </div>

                  <div>
                    <dt>ISO availability</dt>
                    <dd>{format(licenseInfo?.iso?.is_available ?? false)}</dd>
                  </div>

                  <div>
                    <dt>Body attributes estimation availability</dt>
                    <dd>{format(licenseInfo?.body_attributes?.is_available ?? false)}</dd>
                  </div>

                  <div>
                    <dt>People counter availability</dt>
                    <dd>{format(licenseInfo?.people_counter?.is_available ?? false)}</dd>
                  </div>

                  <div>
                    <dt>Index availability</dt>
                    <dd>{format(licenseInfo?.index?.is_available ?? false)}</dd>
                  </div>

                  <div>
                    <dt>Lambdas availability</dt>
                    <dd>{format(licenseInfo?.lambdas?.is_available ?? false)}</dd>
                  </div>

                  <div>
                    <dt>Deepfake availability</dt>
                    <dd>{format(licenseInfo?.deepfake?.is_available ?? false)}</dd>
                  </div>
                </dl>

                <div className={st.Footer}>
                  <div>{`api: ${version.api}, version: ${version.major}.${version.minor}.${version.patch}`}</div>
                </div>
              </div>
            </Panel>
          </GridCol>

        </GridRow>
      </Grid>
    </Page>
  );
};

const licenseFeaturePropType = PropTypes.shape({
  is_available: PropTypes.bool,
  value: PropTypes.any,
});

InfoPage.propTypes = {
  version: PropTypes.shape({
    api: PropTypes.number,
    major: PropTypes.number,
    minor: PropTypes.number,
    patch: PropTypes.number,
  }),
  licenseInfo: PropTypes.shape({
    expiration_time: licenseFeaturePropType,
    faces_limit: licenseFeaturePropType,
    liveness: licenseFeaturePropType,
    liveness_balance: licenseFeaturePropType,
    streams_limit: licenseFeaturePropType,
    iso: licenseFeaturePropType,
    body_attributes: licenseFeaturePropType,
    people_counter: licenseFeaturePropType,
    index: licenseFeaturePropType,
    lambdas: licenseFeaturePropType,
    deepfake: licenseFeaturePropType,
  }),
  sysInfo: PropTypes.shape({
    current_faces_attributes_count: PropTypes.number,
  }),
};

InfoPage.defaultProps = {
  version: {},
  licenseInfo: {},
  sysInfo: {},
};

export default connect((state) => ({
  sysInfo: selectSysInfo(state),
  licenseInfo: selectLicenseInfo(state),
  version: selectVersion(state),
}))(InfoPage);
