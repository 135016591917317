import React, { useState } from 'react';

import { Pages } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as store from 'features/auth/store';

function LoginPage({
  login,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(null);

  const loginHandler = async ({ login: username, password }) => {
    const result = await login(username, password);
    if (!result) {
      history.push('/');
      return;
    }
    if (result?.response?.status === 401) setError(t('api:аутентификация.401'));
  };

  return (
    <Pages.LoginPage
      error={error}
      onSubmit={loginHandler}
    />
  );
}

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
};

export default connect(() => ({}), (dispatch) => ({
  login: (username, password) => dispatch(store.login(username, password)),
}))(LoginPage);
