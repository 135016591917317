import React, { useMemo, useCallback } from 'react';

import { Page, Panel, Table, openConfirmPopup, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DeleteCell from 'components/delete-cell/DeleteCell';
import GoToCell from 'components/goTo-cell/GoToCell';
import OptionCell from 'components/option-cell/OptionCell';
import CreateAccountForm from 'features/account-list/CreateAccountForm';
import useModal from 'hooks/useModal';
import { actionColumnProps } from 'tables/helpers';

import { selectAccountsPage } from './selectors';
import * as store from './store';

const AccountListPage = ({
  accounts,
  createAccount,
  deleteAccount,
  setAccountPageSize,
  setAccountPageIndex,
}) => {
  const { t } = useTranslation();
  const buildLink = (result) => `/accounts/${result.account_id}`;
  const modal = useModal();

  const onDeleteHandler = useCallback((account) => {
    openConfirmPopup({
      title: t('accounts:удаление.подтверждение.заголовок'),
      message: t('accounts:удаление.подтверждение.сообщение', { account }),
      type: 'delete',
      onConfirm: async () => { await deleteAccount(account); },
    });
  }, [deleteAccount, t]);

  const onFormSubmit = async (data) => {
    const errors = await createAccount(data);
    if (errors === undefined) modal.close();
    return errors;
  };

  const columns = useMemo(() => ([
    { Header: t('accounts:таблица.колонки.login'), accessor: 'login' },
    { Header: t('accounts:таблица.колонки.account_type'), accessor: 'account_type', Cell: OptionCell },
    { Header: t('accounts:таблица.колонки.account_id'), accessor: 'account_id' },
    { Header: t('accounts:таблица.колонки.description'), accessor: 'description' },
    actionColumnProps({ id: 'goToCell', Cell: GoToCell, accessor: buildLink, width: 15 }),
    actionColumnProps({ id: 'delete', Cell: DeleteCell, width: 15 }),
  ]), [t]);

  const pageActions = (
    <Control.Button onClick={modal.open}>
      {t('accounts:таблица.создать')}
    </Control.Button>
  );

  return (
    <Page actions={pageActions}>
      <Divider small />
      <Panel>
        {modal.wrap(<CreateAccountForm onSubmit={onFormSubmit} />)}
        <Table
          {...accounts}
          columns={columns}
          setPageIndex={setAccountPageIndex}
          setPageSize={setAccountPageSize}
          paginationType="offsetBased"
          disableSortBy={false}
          onActions={{
            onDeleteRow: { handler: onDeleteHandler },
          }}
        />
      </Panel>
    </Page>
  );
};

AccountListPage.propTypes = {
  accounts: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    meta: PropTypes.shape({
      count: PropTypes.number,
    }),
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  createAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  setAccountPageSize: PropTypes.func.isRequired,
  setAccountPageIndex: PropTypes.func.isRequired,
};

AccountListPage.defaultProps = {
  accounts: {
    data: [],
    meta: { count: 0 },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

export default connect(
  (state) => ({
    accounts: selectAccountsPage(state),
  }),
  (dispatch) => ({
    createAccount: (data) => dispatch(store.createAccount(data)),
    deleteAccount: (accountId) => dispatch(store.deleteAccount(accountId)),
    setAccountPageSize: (pageSize) => {
      dispatch(store.setPageSize(pageSize));
      dispatch(store.fetchAccounts);
    },
    setAccountPageIndex: (pageIndex) => {
      dispatch(store.setPageIndex(pageIndex));
      dispatch(store.fetchAccounts);
    },
  }),
)(AccountListPage);
