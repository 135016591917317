/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Page, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectTaskErrors, selectTaskInfo } from './selectors';
import * as store from './store';
import TaskErrors from './TaskErrors';
import TaskInfo from './TaskInfo';

function TaskPage({
  task,
  errors,
  fetchTaskInfo,
  resetStore,
  setErrorsPageSize,
  setErrorsPageIndex,
}) {
  const { t } = useTranslation();
  useEffect(() => resetStore, [resetStore]);

  return (
    <Page>
      <h6>{t('tasks:общая информация.заголовок')}</h6>
      <Divider small />

      <TaskInfo
        fetchTaskInfo={fetchTaskInfo}
        data={task ? [task] : []}
      />

      <Divider />
      <h6>{t('tasks:таблица ошибок.заголовок')}</h6>
      <Divider small />

      <TaskErrors
        {...errors}
        setPageIndex={setErrorsPageIndex}
        setPageSize={setErrorsPageSize}
      />
    </Page>
  );
}

TaskPage.propTypes = {
  task: PropTypes.shape({}),
  errors: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    meta: PropTypes.shape({
      count: PropTypes.number,
    }),
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  setErrorsPageSize: PropTypes.func.isRequired,
  setErrorsPageIndex: PropTypes.func.isRequired,
  fetchTaskInfo: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
};

TaskPage.defaultProps = {
  task: undefined,
  errors: {
    data: [],
    meta: { count: 0 },
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  },
};

export default connect((state) => ({
  task: selectTaskInfo(state),
  errors: selectTaskErrors(state),
}),
(dispatch, { match: { params: { taskId } } }) => ({
  fetchTaskInfo: () => dispatch(store.fetchTaskInfo(taskId)),
  resetStore: () => dispatch(store.resetStore),
  setErrorsPageIndex: (pageIndex) => {
    dispatch(store.setErrorsPageIndex(pageIndex));
    dispatch(store.fetchTaskErrors(taskId));
  },
  setErrorsPageSize: (pageSize) => {
    dispatch(store.setErrorsPageSize(pageSize));
    dispatch(store.fetchTaskErrors(taskId));
  },
}))(TaskPage);
