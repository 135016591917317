import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from '.';

const selectSlice = (state) => state[SLICE_NAME];

export const selectSysInfo = createSelector(
  selectSlice,
  ({ sysInfo }) => sysInfo,
);
export const selectDescriptorVersionOptions = createSelector(
  selectSlice,
  ({ descriptorVersions }) => descriptorVersions,
);
export const selectLicenseInfo = createSelector(
  selectSlice,
  ({ licenseInfo }) => licenseInfo,
);
export const selectVersion = createSelector(
  selectSlice,
  ({ version }) => version,
);
export const selectLISAvailability = createSelector(
  selectSysInfo,
  (sysInfo) => ({
    taskResults: sysInfo?.services_versions?.['luna-image-store-task-results'] !== undefined,
    facesSamples: sysInfo?.services_versions?.['luna-image-store-faces-samples'] !== undefined,
    bodiesSamples: sysInfo?.services_versions?.['luna-image-store-bodies-samples'] !== undefined,
    images: sysInfo?.services_versions?.['luna-image-store-images'] !== undefined,
  }),
);
export const selectServiceAvailability = createSelector(
  selectSysInfo,
  (_, serviceName) => serviceName,
  (sysInfo, serviceName) => sysInfo?.services_versions?.[serviceName] !== undefined,
);
