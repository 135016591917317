import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import { DEFAULT_HEALTHCHECK_TIMEOUT } from 'api-bindings/config';

import { LunaError } from './luna-client/LunaError';

export class BaseLunaClient {
  constructor({
    apiVersion,
    baseUrl = '',
    afterInit = undefined,
  }) {
    this.apiVersion = apiVersion;
    this.state = {
      version: undefined,
      afterInit,
    };
    this.setBaseURL(baseUrl);
  }

  setBaseURL(url) {
    this.baseURL = url.replace(/\/\/*/, '/');
    this.baseURLWithVersion = `${this.baseURL}/${this.apiVersion}`;
    this.http = Axios.create({
      baseURL: this.baseURLWithVersion,
      paramsSerializer: (params) => qs.stringify(params, {
        arrayFormat: 'comma',
        serializeDate: formatISO,
      }),
    });
    this.state.version = undefined;
    this.init();
    this.addInterceptors();
  }

  addInterceptors() {
    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.data?.error_code
          && error?.response?.data?.desc
          && error?.response?.data?.detail
          && error?.response?.data?.link
        ) {
          throw new LunaError(error);
        }

        throw error;
      },
    );
  }

  healthcheck() {
    return this.http.get('/healthcheck', {
      timeout: DEFAULT_HEALTHCHECK_TIMEOUT,
      baseURL: this.baseURL,
    }).catch((e) => {
      if (e?.code === 'ECONNABORTED') throw e;
      if (e?.response?.status >= 500) throw e;
    });
  }

  async version() {
    if (this.state.version) return this.state.version;
    const { data: { Version } } = await this.http.get('/version', { baseURL: this.baseURL });
    return Version;
  }

  get uiDocsURL() {
    return `${this.baseURLWithVersion}/docs/spec`;
  }

  init() {
    this.version()
      .then((version) => {
        this.state.version = version;
        if (this.state.afterInit) this.state.afterInit();
      }).catch((error) => {
        console.error(`Service ${this.constructor.name} could not be initialized`, error);
      });
  }
}
