// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/reporter.py
import { get, Schema } from 'api-bindings/json-transform';

import { baseTask } from './base';
import { accountFilter } from './filters';

export const content = new Schema({
  filters: {
    ...accountFilter,
    task_id: get('task_id').asString(),
  },
  columns: get('columns').asArrayOfOptions(),
  csv_delimiter: get('csv_delimiter').asString(),
  save_images: get('save_images').asBoolean(),
});

export const reportTaskSchema = new Schema({
  ...baseTask,
  content: get('content').asSchema(content),
});
