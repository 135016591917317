import React, { useEffect } from 'react';

import { Page, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectLISAvailability, selectServiceAvailability } from 'features/info/selectors';

import * as validate from 'forms/validators';

import st from './CreateTaskForm.module.sass';
import { GarbageCollectionTargetSubForm } from './GarbageCollectionTargetSubForm';
import { eventsInitialValues, facesInitialValues } from './gcInitialValues';

function GarbageCollectionForm({
  LISAvailability,
  isLunaEventsAvailable,
  onSubmit,
}) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      description: '',
      content: isLunaEventsAvailable ? eventsInitialValues(LISAvailability) : facesInitialValues(LISAvailability),
    } });

  const { handleSubmit, register, formState: { errors }, reset } = formMethods;

  useEffect(() => {
    reset({
      description: '',
      content: isLunaEventsAvailable ? eventsInitialValues(LISAvailability) : facesInitialValues(LISAvailability),
    });
  }, [isLunaEventsAvailable, reset]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page title={t('tasks:GCTask.заголовок')} className={st.Form}>
          <Divider small />

          <Control.Input
            id="description"
            {...register('description', {
              maxLength: validate.maxLengthValidator(128),
            })}
            label={t('tasks:GCTask.form.description.label')}
            placeholder={t('tasks:GCTask.form.description.placeholder')}
            errors={errors}
          />
          <Divider small />

          <GarbageCollectionTargetSubForm />
          <Divider small />

          <Control.Button type="submit" fullWidth>
            {t('tasks:GCTask.form.submit', { context: 'создать' })}
          </Control.Button>
          <Divider small />
        </Page>
      </form>
    </FormProvider>
  );
}

GarbageCollectionForm.propTypes = {
  LISAvailability: PropTypes.shape({
    taskResults: PropTypes.bool,
    facesSamples: PropTypes.bool,
    bodiesSamples: PropTypes.bool,
  }),
  isLunaEventsAvailable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

GarbageCollectionForm.defaultProps = {
  LISAvailability: undefined,
  isLunaEventsAvailable: false,
};

export default connect((state) => ({
  LISAvailability: selectLISAvailability(state),
  isLunaEventsAvailable: selectServiceAvailability(state, 'luna-events'),
}))(GarbageCollectionForm);
