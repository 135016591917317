// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/linker.py
import { get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../../constants';
import { baseTask } from './base';
import { eventFiltersWithAccount, faceFiltersWithAccount } from './filters';

export const content = new Schema({
  list_id: get('list_id').asString().ifEmptyString(undefined),
  create_list: get('create_list').asInt().ifNaN(undefined),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  objects_type: get('objects_type').asOptionValue(),

  filters: get().switch(({ objects_type }) => objects_type?.value, {
    [ENUMS.OBJECTS_TYPE.events.value]: get('filters')
      .asSchema(new Schema(faceFiltersWithAccount)),
    [ENUMS.OBJECTS_TYPE.faces.value]: get('filters')
      .asSchema(new Schema(eventFiltersWithAccount)),
  }),
});

export const linkerTask = new Schema({
  ...baseTask,
  content: get('content').asSchema(content),
});
