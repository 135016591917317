import React from 'react';

import { TableCells } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

function DeleteCell(props) {
  const { t } = useTranslation();
  return (
    <div title={t('иконки.удалить')} style={{ lineHeight: 0 }}>
      {TableCells.DeleteCell(props)}
    </div>
  );
}

export default DeleteCell;
