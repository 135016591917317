import { createSlice } from '@reduxjs/toolkit';

import { selectAppState } from 'features/app/selectors';

import { LunaAdminClient } from 'api/index';

const initialState = {
  state: undefined,
  data: [],
  meta: {},
  pageIndex: 0,
  pageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
};

const store = createSlice({
  name: 'list',
  initialState,
  reducers: {
    setLists(state, { payload: { lists, list_count } }) {
      state.data = lists;
      state.meta = { list_count };
      state.state = 'loaded';
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
  },
});

export default store.reducer;

export const fetchListsByAccount = (account_id) => async (dispatch, getState) => {
  if (selectAppState(getState()) !== 'loaded') return;
  const {
    lists: { pageIndex, pageSize },
  } = getState();

  try {
    const { data: lists } = await LunaAdminClient.lists
      .showAll({ page: pageIndex + 1, page_size: pageSize, account_id });
    dispatch(store.actions.setLists(lists));
  } catch (error) {
    dispatch(store.actions.setLists({ data: [] }));
    throw error;
  }
};

export const getLists = async (params) => LunaAdminClient.lists.showAll({ ...params });

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(store.actions.setPageSize(params));
  dispatch(fetchListsByAccount);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(store.actions.setPage(params));
  dispatch(fetchListsByAccount);
};
