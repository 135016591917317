import { combineReducers, configureStore, isPlain } from '@reduxjs/toolkit';

import accountListSlice from 'features/account-list/store';
import accountSlice from 'features/account/store';
import appSlice from 'features/app/store';
import authSlice from 'features/auth/store';
import infoSlice from 'features/info/store';
import listsSlice from 'features/lists/store';
import taskListSlice from 'features/task-list/store';
import taskSlice from 'features/task/store';

const reducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  account: accountSlice,
  accountList: accountListSlice,
  info: infoSlice,
  lists: listsSlice,
  taskList: taskListSlice,
  task: taskSlice,
});

const isSerializable = (value) => value instanceof Date || isPlain(value);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      isSerializable,
    },
  }),
});

export default store;
