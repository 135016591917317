import { createSlice } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';

import { LunaAdminClient } from 'api/index';

import { SLICE_NAME } from '.';

const initialState = {
  state: undefined,
  descriptorVersions: [],
  sysInfo: {},
  licenseInfo: {},
  version: {},
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setDescriptorVersions(state, { payload }) {
      state.descriptorVersions = payload;
    },
    setLicenseInfo(state, { payload }) {
      state.licenseInfo = payload;
    },
    setSysInfo(state, { payload }) {
      state.sysInfo = payload;
      state.state = 'loaded';
    },
    setVersion(state, { payload }) {
      state.version = payload;
    },
  },
});

export default store.reducer;

export const fetchSystemInfo = async (dispatch) => {
  const { data } = await LunaAdminClient.help.getSystemInfo();
  const { descriptor_info, license_info } = data;
  const descriptorVersions = descriptor_info.map(
    ({ descriptor_version }) => ({ value: descriptor_version, label: descriptor_version }),
  );
  dispatch(store.actions.setDescriptorVersions(descriptorVersions));
  dispatch(store.actions.setLicenseInfo(license_info));
  dispatch(store.actions.setSysInfo(data));
};

export const fetchSystemVersion = async (dispatch) => {
  const version = await LunaAdminClient.help.getVersion();
  dispatch(store.actions.setVersion(version));
};

export const getSystemResult = async () => {
  const { data } = await LunaAdminClient.help.getSystemInfo();
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  FileSaver.saveAs(blob, `${'luna_sys_info'}.json`);
};
