// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/additional_extract.py
import { CleanSchema, get, Schema } from 'api-bindings/json-transform';

import { LUNA_TASK_ENUMS as ENUMS } from '../../constants';
import { baseTask } from './base';

export const options = {
  missing: get('options.missing').asBoolean(),
};

export const optionsWithFaceDescriptorVersion = {
  ...options,
  descriptor_version: get('options.descriptor_version.value').asInt().ifNaN(undefined),
};
export const optionsWithBodyDescriptorVersion = {
  descriptor_version: get('options.descriptor_version.value').asInt().ifNaN(undefined),
};

export const faceFilters = {
  objects_type: get('objects_type').asOptionValue(),
  account_id: get('account_id').asString().ifEmptyString(undefined),
  face_id__gte: get('face_id__gte').asString().ifEmptyString(undefined),
  face_id__lt: get('face_id__lt').asString().ifEmptyString(undefined),
};

export const eventFilters = {
  objects_type: get('objects_type').asOptionValue(),
  insert_time__gte: get('insert_time__gte').asISOString({ unpack: true }),
  insert_time__lt: get('insert_time__lt').asISOString({ unpack: true }),
  account_id: get('account_id').asString().ifEmptyString(undefined),
  handler_id: get('handler_id').asString().ifEmptyString(undefined),
  event_id__gte: get('event_id__gte').asString().ifEmptyString(undefined),
  event_id__lt: get('event_id__lt').asString().ifEmptyString(undefined),
};

export const content = new Schema({
  extraction_target: get('extraction_target').asOptionValue(),
  options: get().switch((_content) => _content?.extraction_target?.value, {
    [ENUMS.EXTRACTION_TARGET.basic_attributes.value]: new Schema(options),
    [ENUMS.EXTRACTION_TARGET.face_descriptor.value]: new Schema(optionsWithFaceDescriptorVersion),
    [ENUMS.EXTRACTION_TARGET.body_descriptor.value]: new Schema(optionsWithBodyDescriptorVersion),
  }),
  filters: get().switch((_content) => _content?.extraction_target?.value, {
    [ENUMS.EXTRACTION_TARGET.basic_attributes.value]: get('filters')
      .switch(((filters) => filters?.objects_type?.value), {
        [ENUMS.OBJECTS_TYPE.faces.value]: new CleanSchema(faceFilters),
        [ENUMS.OBJECTS_TYPE.events.value]: new CleanSchema(eventFilters),
      }),
    [ENUMS.EXTRACTION_TARGET.face_descriptor.value]: get('filters')
      .switch(((filters) => filters?.objects_type?.value), {
        [ENUMS.OBJECTS_TYPE.faces.value]: new CleanSchema(faceFilters),
        [ENUMS.OBJECTS_TYPE.events.value]: new CleanSchema(eventFilters),
      }),
    [ENUMS.EXTRACTION_TARGET.body_descriptor.value]: get('filters')
      .switch(((filters) => filters?.objects_type?.value), {
        [ENUMS.OBJECTS_TYPE.events.value]: new CleanSchema(eventFilters),
      }),
  }),
});

export const additionalExtractTask = new Schema({
  ...baseTask,
  content: get('content').asSchema(content),
});
