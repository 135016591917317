import React, { useEffect } from 'react';

import { Page, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectDescriptorVersionOptions, selectServiceAvailability } from 'features/info/selectors';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import * as validate from 'forms/validators';

import { makeInitialValues } from './additionalExtractionInitialValues';
import { AdditionalExtractionTargetSubForm } from './AdditionalExtractionTargetSubForm';
import st from './CreateTaskForm.module.sass';

function AdditionalExtractionFormComponent({
  descriptorVersionOptions,
  isLunaEventsAvailable,
  onSubmit,
}) {
  const { t } = useTranslation();

  const defaultValues = makeInitialValues({
    descriptorVersionOptions,
    objects_type: isLunaEventsAvailable ? LUNA_TASK_ENUMS.OBJECTS_TYPE.events : LUNA_TASK_ENUMS.OBJECTS_TYPE.faces,
    extraction_target: LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor,
  });
  const form = useForm({ defaultValues });

  useEffect(() => {
    form.reset(makeInitialValues({
      descriptorVersionOptions,
      objects_type: isLunaEventsAvailable ? LUNA_TASK_ENUMS.OBJECTS_TYPE.events : LUNA_TASK_ENUMS.OBJECTS_TYPE.faces,
      extraction_target: LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor,
    }));
  }, [form.reset, descriptorVersionOptions, isLunaEventsAvailable]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Page title={t('tasks:additionalExtraction.заголовок')} className={st.Form}>
          <Divider small />

          <Control.Input
            id="description"
            label={t('tasks:additionalExtraction.форма.description.label')}
            placeholder={t('tasks:additionalExtraction.форма.description.placeholder')}
            {...form.register('description', {
              maxLength: validate.maxLengthValidator(128),
            })}
            errors={form.formState.errors}
          />
          <Divider small />

          <AdditionalExtractionTargetSubForm />
          <Divider small />

          <Control.Button type="submit" fullWidth>
            {t('tasks:additionalExtraction.форма.submit', { context: 'create' })}
          </Control.Button>

        </Page>
      </form>
    </FormProvider>
  );
}

AdditionalExtractionFormComponent.propTypes = {
  descriptorVersionOptions: PropTypes.arrayOf(PropTypes.any),
  isLunaEventsAvailable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AdditionalExtractionFormComponent.defaultProps = {
  isLunaEventsAvailable: false,
  descriptorVersionOptions: [],
};

export const AdditionalExtractionForm = connect((state) => ({
  isLunaEventsAvailable: selectServiceAvailability(state, 'luna-events'),
  descriptorVersionOptions: selectDescriptorVersionOptions(state),
}))(AdditionalExtractionFormComponent);
