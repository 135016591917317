import { createSelector } from '@reduxjs/toolkit';

export const selectTasksSlice = (state) => state.taskList;

export const selectTasks = createSelector(
  selectTasksSlice,
  ({ data, meta, filters, pageIndex, pageSize, pageSizeOptions }) => (
    { data, meta, filters, pageIndex, pageSize, pageSizeOptions }
  ),
);

export const selectTaskById = createSelector(
  selectTasksSlice,
  (taskId) => (taskId),
  ({ byId }, taskId) => byId[taskId],
);
