import React from 'react';

import { DownloadIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withHandler } from 'components/hocs/withHandler';

import st from './ResultCell.module.sass';

const ResultCell = ({
  onClick,
  row: { original },
}) => {
  const { t } = useTranslation();
  const { task_status } = original;

  if (![5].includes(task_status.value)) return null;

  return (
    <div className={st.ResultCellWrapper} title={t('иконки.результат')}>
      <DownloadIcon
        className={st.Icon}
        onClick={() => onClick(original)}
      />
    </div>
  );
};

ResultCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      task_status: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default (onClick) => withHandler(onClick)(ResultCell);
