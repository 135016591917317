import { LUNA_TASK_ENUMS as ENUMS } from 'api-bindings/luna-tasks/constants';
import { additionalExtractTaskSchema } from 'api-bindings/luna-tasks/schemas/get/additionalExtract';
import { baseTaskSchema } from 'api-bindings/luna-tasks/schemas/get/base';
import { clusterizationTaskSchema } from 'api-bindings/luna-tasks/schemas/get/clustering';
import { gcTaskSchema } from 'api-bindings/luna-tasks/schemas/get/gc';
import { linkerTaskSchema } from 'api-bindings/luna-tasks/schemas/get/linker';
import { reportTaskSchema } from 'api-bindings/luna-tasks/schemas/get/reporter';

export const getTask = (task) => {
  switch (task.task_type) {
    case ENUMS.TASK_TYPE[1].value: return linkerTaskSchema(task);
    case ENUMS.TASK_TYPE[2].value: return clusterizationTaskSchema(task);
    case ENUMS.TASK_TYPE[3].value: return reportTaskSchema(task);
    case ENUMS.TASK_TYPE[4].value: return gcTaskSchema(task);
    case ENUMS.TASK_TYPE[5].value: return additionalExtractTaskSchema(task);
    default: {
      return baseTaskSchema(task);
    }
  }
};
