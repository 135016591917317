import React, { useEffect, useMemo } from 'react';

import { Control, Divider, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { selectDescriptorVersionOptions, selectLISAvailability, selectServiceAvailability } from 'features/info/selectors';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import { AdditionalExtractionTargetSubForm } from 'features/task-list/AdditionalExtractionTargetSubForm';
import { GarbageCollectionTargetSubForm } from 'features/task-list/GarbageCollectionTargetSubForm';
import * as validate from 'forms/validators';

import st from './EditScheduleForm.module.sass';
import { buildValueCleaner, makeDefaultValues } from './scheduleDefaultValues';
import { EDITABLE_TASK_TYPES_OPTIONS } from './settings';

function EditScheduleFormComponent({
  values,
  context,
  onSubmit,
  LISAvailability,
  isLunaEventsAvailable,
  descriptorVersionOptions,
}) {
  const { t } = useTranslation();

  const form = useForm({ defaultValues: values || makeDefaultValues({
    task_type: LUNA_TASK_ENUMS.TASK_TYPE[4],
    isLunaEventsAvailable,
    descriptorVersionOptions,
  }) });

  const cleaner = useMemo(() => {
    return buildValueCleaner({ LISAvailability, isLunaEventsAvailable });
  }, [LISAvailability, isLunaEventsAvailable]);

  useEffect(() => {
    if (values === undefined) {
      form.reset(cleaner(makeDefaultValues({
        task_type: LUNA_TASK_ENUMS.TASK_TYPE[4],
        isLunaEventsAvailable,
        descriptorVersionOptions,
      })).values);
      return;
    }

    const updatedValues = cleaner(values);
    if (updatedValues.cleanedFields.length === 0) {
      form.reset(updatedValues.values);
      return;
    }

    toast.warn(
      <div className={st.UpdatedPaths}>
        Values at paths:
        <ul>
          {updatedValues.cleanedFields.map((cleanedPath) => (
            <li key={cleanedPath}>
              <strong>{cleanedPath}</strong>
            </li>
          ))}
        </ul>
        was changed accordingly to current services configuration.
      </div>);
  }, [form.reset, values, cleaner]);

  form.watch((data, { name }) => {
    if (name === 'task.task_type') {
      if (context === 'create') {
        form.reset(cleaner(makeDefaultValues({
          task_type: data.task.task_type,
          isLunaEventsAvailable,
          descriptorVersionOptions,
        })).values);
      }
    }
  });

  const taskType = form.watch('task.task_type');

  return (
    <Page title={t('schedules:form.title')} className={st.Form}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Control.Select
            id="task.task_type"
            name="task.task_type"
            label={t('schedules:form.task.task_type.label')}
            options={EDITABLE_TASK_TYPES_OPTIONS}
            rules={{ required: validate.requiredValidator() }}
            control={form.control}
            errors={form.formState.errors}
            disabled={context === 'update'}
          />
          <Divider small />

          {taskType?.value === 4 && (
            <>
              <GarbageCollectionTargetSubForm prefix="task" />
              <Divider small />
            </>
          )}
          {taskType?.value === 5 && (
            <>
              <AdditionalExtractionTargetSubForm prefix="task" />
              <Divider small />
            </>
          )}

          <h6>{t('schedules:form.layout.sections.trigger')}</h6>
          <Control.Input
            id="trigger.cron"
            {...form.register('trigger.cron', {
              required: validate.requiredValidator(),
              maxLength: validate.maxLengthValidator(128),
              validate: {
                cron: validate.cronValidator(),
              },
            })}
            label={t('schedules:form.trigger.cron.label')}
            placeholder={t('schedules:form.trigger.cron.placeholder')}
            errors={form.formState.errors}
          />
          <Divider small />

          <Control.Select
            id="trigger.cron_timezone"
            name="trigger.cron_timezone"
            label={t('schedules:form.trigger.cron_timezone.label')}
            options={LUNA_TASK_ENUMS.SCHEDULE_CRON_TIMEZONE.raw}
            control={form.control}
            errors={form.formState.errors}
          />
          <Divider small />

          <Control.Switch
            id="behaviour.start_immediately"
            name="behaviour.start_immediately"
            label={t('schedules:form.behaviour.start_immediately.label')}
            control={form.control}
            errors={form.formState.errors}
          />
          <Divider small />

          <Control.Switch
            id="behaviour.create_stopped"
            name="behaviour.create_stopped"
            label={t('schedules:form.behaviour.create_stopped.label')}
            control={form.control}
            errors={form.formState.errors}
          />
          <Divider small />

          <Control.Button type="submit" fullWidth>
            {t('schedules:form.submit', { context })}
          </Control.Button>
        </form>
      </FormProvider>
    </Page>
  );
}

EditScheduleFormComponent.propTypes = {
  // Values могут отличаться в зависимости от типа задачи
  values: PropTypes.shape({}),
  context: PropTypes.oneOf(['create', 'update']),
  onSubmit: PropTypes.func.isRequired,
  LISAvailability: PropTypes.shape({
    taskResults: PropTypes.bool,
  }),
  isLunaEventsAvailable: PropTypes.bool,
  descriptorVersionOptions: PropTypes.arrayOf(PropTypes.any),
};

EditScheduleFormComponent.defaultProps = {
  values: undefined,
  context: 'create',
  LISAvailability: {
    taskResults: false,
  },
  isLunaEventsAvailable: false,
  descriptorVersionOptions: [],
};

export const EditScheduleForm = connect((state) => ({
  LISAvailability: selectLISAvailability(state),
  isLunaEventsAvailable: selectServiceAvailability(state, 'luna-events'),
  descriptorVersionOptions: selectDescriptorVersionOptions(state),
}))(EditScheduleFormComponent);
