import React, { useMemo, useCallback } from 'react';

import {
  Page, Divider, Panel, Table, TableCells, Control, openConfirmPopup, TableFilters,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectDescriptorVersionOptions, selectServiceAvailability } from 'features/info/selectors';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import CancelCell from 'components/cancel-cell/CancelCell';
import DeleteCell from 'components/delete-cell/DeleteCell';
import GoToCell from 'components/goTo-cell/GoToCell';
import OptionCell from 'components/option-cell/OptionCell';
import ProgressCell from 'components/progress-cell/ProgressCell';
import ResultCell from 'components/result-cell/ResultCell';
import useModal from 'hooks/useModal';
import { actionColumnProps } from 'tables/helpers';

import { AdditionalExtractionForm } from './AdditionalExtractionForm';
import GarbageCollectionForm from './GarbageCollectionForm';
import { selectTasks } from './selectors';
import * as store from './store';
import st from './TaskListPage.module.sass';

function TaskListPage({
  tasks,
  descriptorVersions,
  deleteTask,
  cancelTask,
  getTaskResult,
  createGCTask,
  createAdditionalExtractionTask,
  setTasksPageSize,
  setTasksPageIndex,
  setFilters,
  isLunaTasksAvailable,
}) {
  const { t } = useTranslation();
  const gcModal = useModal();
  const reModal = useModal();

  const onGarbageCollectionFormSubmit = (params) => {
    openConfirmPopup({
      title: t('tasks:GCTask.подтверждение.заголовок'),
      message: t('tasks:GCTask.подтверждение.сообщение'),
      type: 'warning',
      onConfirm: async () => {
        await createGCTask(params);
        gcModal.close();
      },
    });
  };

  const onAdditionalExtractionFormSubmit = (params) => {
    openConfirmPopup({
      title: t('tasks:additionalExtraction.подтверждение.заголовок'),
      message: t('tasks:additionalExtraction.подтверждение.сообщение'),
      type: 'warning',
      onConfirm: async () => {
        await createAdditionalExtractionTask(params);
        reModal.close();
      },
    });
  };

  const onCancelHandler = useCallback((task) => {
    openConfirmPopup({
      title: t('tasks:остановить.подтверждение.заголовок'),
      message: t('tasks:остановить.подтверждение.сообщение', { task }),
      type: 'warning',
      onConfirm: async () => { cancelTask(task.task_id); },
    });
  }, [cancelTask, t]);

  const onResultHandler = useCallback(async (task) => getTaskResult(task.task_id), [getTaskResult]);

  const onDeleteHandler = (task) => {
    openConfirmPopup({
      title: t('tasks:удалить.подтверждение.заголовок'),
      message: t('tasks:удалить.подтверждение.сообщение', { task }),
      type: 'delete',
      onConfirm: async () => { await deleteTask(task.task_id); },
    });
  };

  const buildTaskLink = ({ task_id: taskId }) => `/tasks/${taskId}`;
  const columns = useMemo(() => ([
    { Header: t('tasks:таблица.колонки.task_id'), accessor: 'task_id', Filter: TableFilters.TextFilter(), width: 85 },
    { Header: t('tasks:таблица.колонки.schedule_id'), accessor: 'schedule_id', Filter: TableFilters.TextFilter(), width: 85 },
    { Header: t('tasks:таблица.колонки.task_type'), accessor: 'task_type', Cell: OptionCell, Filter: TableFilters.OneOfFilter({ options: LUNA_TASK_ENUMS.TASK_TYPE.raw }) },
    { Header: t('tasks:таблица.колонки.create_time'), accessor: 'create_time', Cell: TableCells.DateCell, Filter: TableFilters.DateFilter() },
    { Header: t('tasks:таблица.колонки.last_update_time'), accessor: 'last_update_time', Cell: TableCells.DateCell },
    { Header: t('tasks:таблица.колонки.end_time'), accessor: 'end_time', Cell: TableCells.DateCell, Filter: TableFilters.DateFilter() },
    { Header: t('tasks:таблица.колонки.count_task_parts_done'), accessor: 'count_task_parts_done', width: 100 },
    { Header: t('tasks:таблица.колонки.count_task_parts_all'), accessor: 'count_task_parts_all', width: 100 },
    { Header: t('tasks:таблица.колонки.description'), accessor: 'description' },
    { Header: t('tasks:таблица.колонки.task_status'), accessor: 'task_status', Cell: ProgressCell, Filter: TableFilters.OneOfFilter({ options: LUNA_TASK_ENUMS.TASK_STATUS.raw }), width: 100 },
    actionColumnProps({ id: 'cancelCell', Cell: CancelCell(onCancelHandler), width: 30 }),
    actionColumnProps({ id: 'resultCell', Cell: ResultCell(onResultHandler), width: 30 }),
    actionColumnProps({ id: 'goToCell', Cell: GoToCell, accessor: buildTaskLink, width: 30 }),
    actionColumnProps({ id: 'delete', Cell: DeleteCell, width: 30 }),
  ]), [onCancelHandler, onResultHandler, t]);

  return (
    <Page>
      {gcModal.wrap(<GarbageCollectionForm
        onSubmit={onGarbageCollectionFormSubmit}
        descriptorVersionOptions={descriptorVersions}
      />)}

      {reModal.wrap(<AdditionalExtractionForm onSubmit={onAdditionalExtractionFormSubmit} />)}

      <div className={st.Header}>
        <div className={st.Inline}>
          <div className={st.Button}>
            <Control.Button
              onClick={gcModal.open}
              data-testid="tasks.create-gc"
              disabled={!isLunaTasksAvailable}
            >
              {t('tasks:GCTask.создать')}
            </Control.Button>
          </div>
          <div className={st.Button}>
            <Control.Button
              onClick={reModal.open}
              data-testid="tasks.create-additionalExtract"
              disabled={!isLunaTasksAvailable}
            >
              {t('tasks:additionalExtraction.создать')}
            </Control.Button>
          </div>
        </div>
      </div>

      <Divider small />

      <Panel>
        <Table
          {...tasks}
          columns={columns}
          setPageIndex={setTasksPageIndex}
          setPageSize={setTasksPageSize}
          setFilters={setFilters}
          paginationType="offsetBased"
          onActions={{
            onDeleteRow: { handler: onDeleteHandler },
          }}
        />
      </Panel>
    </Page>
  );
}

TaskListPage.propTypes = {
  tasks: PropTypes.objectOf(PropTypes.any),
  descriptorVersions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.number,
  })),
  deleteTask: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  getTaskResult: PropTypes.func.isRequired,
  createGCTask: PropTypes.func.isRequired,
  createAdditionalExtractionTask: PropTypes.func.isRequired,
  setTasksPageSize: PropTypes.func.isRequired,
  setTasksPageIndex: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  isLunaTasksAvailable: PropTypes.bool,
};

TaskListPage.defaultProps = {
  tasks: {},
  descriptorVersions: [],
  isLunaTasksAvailable: false,
};

export default connect((state) => ({
  tasks: selectTasks(state),
  descriptorVersions: selectDescriptorVersionOptions(state),
  isLunaTasksAvailable: selectServiceAvailability(state, 'luna-tasks'),
}),
(dispatch) => ({
  deleteTask: (taskId) => dispatch(store.deleteTask(taskId)),
  cancelTask: (taskId) => dispatch(store.cancelTask(taskId)),
  getTaskResult: (taskId) => store.getTaskResult(taskId),
  createGCTask: (data) => dispatch(store.createGCTask(data)),
  createAdditionalExtractionTask: (data) => dispatch(store.createAdditionalExtractionTask(data)),
  setFilters: (filters) => {
    dispatch(store.setFilters({ filters }));
    dispatch(store.fetchTasks);
  },
  setTasksPageSize: (pageSize) => {
    dispatch(store.setPageSize(pageSize));
    dispatch(store.fetchTasks);
  },
  setTasksPageIndex: (pageIndex) => {
    dispatch(store.setPageIndex(pageIndex));
    dispatch(store.fetchTasks);
  },
}))(TaskListPage);
