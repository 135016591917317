import { useEffect, useCallback, useReducer } from 'react';

import { isEqual } from 'lodash';

const initialState = {
  data: [],
  pageIndex: undefined,
  pageSize: 25,
  filters: undefined,
  onFetch: undefined,
};
const actionTypes = {
  SET_PAGE: 'set-page',
  SET_ONFETCH: 'set-on-fetch',
  SET_PAGE_INDEX: 'set-page-index',
  SET_PAGE_SIZE: 'set-page-size',
  SET_FILTERS: 'set-filters',
};

const reducer = (previousState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE: {
      return {
        ...previousState,
        data: action.payload,
      };
    }
    case actionTypes.SET_PAGE_INDEX: {
      return {
        ...previousState,
        pageIndex: action.payload,
      };
    }
    case actionTypes.SET_PAGE_SIZE: {
      return {
        ...previousState,
        pageSize: action.payload,
      };
    }
    case actionTypes.SET_FILTERS: {
      if (isEqual(previousState.filters, action.payload)) return previousState;
      return {
        ...previousState,
        filters: action.payload,
      };
    }
    case actionTypes.SET_ONFETCH: {
      if (action.payload === previousState.onFetch) return previousState;
      return {
        ...initialState,
        onFetch: action.payload,
      };
    }
    default: {
      throw new Error(`action ${action.type} is not registered`);
    }
  }
};

export const useAsyncTableData = (onFetch) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    onFetch,
  });

  const fetch = useCallback(async () => {
    if (state.pageSize === undefined || state.pageIndex === undefined) return;
    const page = await state.onFetch({ page_size: state.pageSize, page: state.pageIndex + 1, ...state.filters });
    dispatch({ type: actionTypes.SET_PAGE, payload: page });
  }, [dispatch, state.pageSize, state.pageIndex, state.filters]);

  const setPageIndex = useCallback((pageIndex) => {
    dispatch({ type: actionTypes.SET_PAGE_INDEX, payload: pageIndex });
  }, [dispatch, state.pageIndex]);

  const setPageSize = useCallback((pageSize) => {
    dispatch({ type: actionTypes.SET_PAGE_SIZE, payload: pageSize });
  }, [dispatch, state.pageSize]);

  const setFilters = useCallback((payload) => {
    dispatch({ type: actionTypes.SET_FILTERS, payload });
  });

  useEffect(() => { fetch(); }, [fetch, state.pageSize, state.pageIndex]);

  return {
    data: state.data,
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
    filters: state.filters,
    setPageIndex,
    setPageSize,
    setFilters,
    fetch,
  };
};
