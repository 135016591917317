// http://git.visionlabs.ru/luna/luna-tasks/-/blob/platform_5/luna_tasks/schemas/filters.py
import { get, Schema } from 'api-bindings/json-transform';

export const accountFilter = {
  account_id: get('account_id').asString().ifEmptyString(undefined),
};
export const optionalAccountFilter = {
  account_id: get('account_id').switch((v) => typeof v, {
    null: null,
    default: get().asString().ifEmptyString(undefined),
  }),
};

const faceFilters = {
  face_ids: get('face_ids').asArrayOfOptionValues(),
  external_ids: get('external_ids').asArrayOfOptionValues(),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  create_time__lt: get('create_time__lt').asISOString({ unpack: true }),
  create_time__gte: get('create_time__gte').asISOString({ unpack: true }),
  face_id__lt: get('face_id__lt').asString().ifEmptyString(undefined),
  face_id__gte: get('face_id__gte').asString().ifEmptyString(undefined),
  list_id: get('list_id').asString().ifEmptyString(undefined),
};

export const faceFiltersWithAccount = {
  ...accountFilter,
  ...faceFilters,
};
export const faceFiltersOptionalAccount = {
  ...optionalAccountFilter,
  ...faceFilters,
};

export const attributeFilters = {
  attribute_ids: get('attribute_ids').asArrayOfOptionValues(),
};

export const geopositionFilters = {
  origin_longitude: get('origin_longitude').asFloat().ifNaN(undefined),
  origin_latitude: get('origin_latitude').asFloat().ifNaN(undefined),
  longitude_delta: get('longitude_delta').asFloat().ifNaN(undefined),
  latitude_delta: get('latitude_delta').asFloat().ifNaN(undefined),
};

export const eventFilters = {
  create_time__lt: get('create_time__lt').asISOString({ unpack: true }),
  create_time__gte: get('create_time__gte').asISOString({ unpack: true }),
  end_time__lt: get('end_time__lt').asISOString({ unpack: true }),
  end_time__gte: get('end_time__gte').asISOString({ unpack: true }),
  sources: get('sources').asArrayOfOptionValues(),
  stream_ids: get('stream_ids').asArrayOfOptionValues(),
  event_ids: get('event_ids').asArrayOfOptionValues(),
  event_id__lt: get('event_id__lt').asString().ifEmptyString(undefined),
  event_id__gte: get('event_id__gte').asString().ifEmptyString(undefined),
  handler_ids: get('handler_ids').asArrayOfOptionValues(),
  top_matching_candidates_label: get('top_matching_candidates_label').asArrayOfOptionValues(),
  top_similar_object_ids: get('top_similar_object_ids').asArrayOfOptionValues(),
  top_similar_external_ids: get('top_similar_external_ids').asArrayOfOptionValues(),
  top_similar_object_similarity__lt: get('top_similar_object_similarity__lt').asFloat().ifNaN(undefined),
  top_similar_object_similarity__gte: get('top_similar_object_similarity__gte').asFloat().ifNaN(undefined),
  age__lt: get('age__lt').asInt().ifNaN(undefined),
  age__gte: get('age__gte').asInt().ifNaN(undefined),
  gender: get('gender').asOptionValue(),
  emotions: get('emotions').asArrayOfOptionValues(),
  ethnic_groups: get('ethnic_groups').asArrayOfOptionValues(),
  face_ids: get('face_ids').asArrayOfOptionValues(),
  user_data: get('user_data').asString().ifEmptyString(undefined),
  tags: get('tags').asArrayOfOptionValues(),
  cities: get('cities').asArrayOfOptionValues(),
  areas: get('areas').asArrayOfOptionValues(),
  districts: get('districts').asArrayOfOptionValues(),
  streets: get('streets').asArrayOfOptionValues(),
  house_numbers: get('house_numbers').asArrayOfOptionValues(),
  external_ids: get('external_ids').asArrayOfOptionValues(),
  geo_position: get('geo_position').asSchema(new Schema(geopositionFilters)),
  masks: get('masks').asArrayOfOptionValues(),
  track_ids: get('track_ids').asArrayOfOptionValues(),
  liveness: get('liveness').asArrayOfOptionValues(),
  apparent_gender: get('apparent_gender').asArrayOfOptionValues(),
  headwear_states: get('headwear_states').asArrayOfOptionValues(),
  headwear_apparent_colors: get('headwear_apparent_colors').asArrayOfOptionValues(),
  sleeve_lengths: get('sleeve_lengths').asArrayOfOptionValues(),
  apparent_age__lt: get('apparent_age__lt').asInt().ifNaN(undefined),
  apparent_age__gte: get('apparent_age__gte').asInt().ifNaN(undefined),
  upper_clothing_colors: get('upper_clothing_colors').asArrayOfOptionValues(),
  backpack_states: get('backpack_states').asArrayOfOptionValues(),
  lower_garment_types: get('lower_garment_types').asArrayOfOptionValues(),
  lower_garment_colors: get('lower_garment_colors').asArrayOfOptionValues(),
  shoes_apparent_colors: get('shoes_apparent_colors').asArrayOfOptionValues(),
  meta: get('meta'),
};

export const eventFiltersWithAccount = {
  ...accountFilter,
  ...eventFilters,
};

export const eventFiltersOptionaAccount = {
  ...optionalAccountFilter,
  ...eventFilters,
};
