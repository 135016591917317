import { createSlice } from '@reduxjs/toolkit';

import { fetchSystemInfo, fetchSystemVersion } from 'features/info/store';

const initialState = {
  state: 'loading',
  services: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateState(state, { payload }) {
      state.state = payload;
    },
  },
});

export const { updateState } = appSlice.actions;

export const initApp = async (dispatch) => {
  dispatch(fetchSystemInfo);
  dispatch(fetchSystemVersion);
  dispatch(updateState('loaded'));
};

export default appSlice.reducer;
