import React, { useMemo } from 'react';

import { Control, Divider } from '@vlabs/uikit';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectDescriptorVersionOptions, selectLISAvailability, selectServiceAvailability } from 'features/info/selectors';

import { LUNA_TASK_ENUMS } from 'api-bindings/luna-tasks/constants';
import * as validate from 'forms/validators';

import { makeFiltersInitialValues, makeOptionsInitialValues } from './additionalExtractionInitialValues';

function AdditionalExtractionTargetSubFormComponent({
  prefix,
  descriptorVersionOptions,
  isLunaEventsAvailable,
}) {
  const form = useFormContext();
  const { t } = useTranslation();

  const buildPrefix = (name) => (prefix ? `${prefix}.${name}` : name);

  const objects_type = form.watch(buildPrefix('content.filters.objects_type'))?.value;
  const extraction_target = form.watch(buildPrefix('content.extraction_target'))?.value;

  const availableExtractionTargetOptions = useMemo(() => {
    const options = [
      LUNA_TASK_ENUMS.EXTRACTION_TARGET.basic_attributes,
      LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor,
    ];

    if (isLunaEventsAvailable) options.push(LUNA_TASK_ENUMS.EXTRACTION_TARGET.body_descriptor);
    return options;
  }, [isLunaEventsAvailable]);

  const availableObjectTypes = useMemo(() => {
    const options = [];
    switch (extraction_target) {
      case LUNA_TASK_ENUMS.EXTRACTION_TARGET.basic_attributes.value: {
        options.push(LUNA_TASK_ENUMS.OBJECTS_TYPE.faces);
        if (isLunaEventsAvailable) options.push(LUNA_TASK_ENUMS.OBJECTS_TYPE.events);
        return options;
      }
      case LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor.value: {
        options.push(LUNA_TASK_ENUMS.OBJECTS_TYPE.faces);
        if (isLunaEventsAvailable) options.push(LUNA_TASK_ENUMS.OBJECTS_TYPE.events);
        return options;
      }
      case LUNA_TASK_ENUMS.EXTRACTION_TARGET.body_descriptor.value: {
        if (isLunaEventsAvailable) options.push(LUNA_TASK_ENUMS.OBJECTS_TYPE.events);
        return options;
      }
      default: {
        return options;
      }
    }
  }, [isLunaEventsAvailable, extraction_target]);

  form.watch((data, { name }) => {
    if (name === buildPrefix('content.extraction_target')) {
      const extractionTarget = get(data, buildPrefix('content.extraction_target'));
      set(data, buildPrefix('content.filters'), makeFiltersInitialValues({
        objects_type: LUNA_TASK_ENUMS.OBJECTS_TYPE.events,
      }));
      set(data, buildPrefix('content.options'), makeOptionsInitialValues({
        extraction_target: extractionTarget,
        descriptorVersionOptions:
          extractionTarget.value === LUNA_TASK_ENUMS.EXTRACTION_TARGET.face_descriptor.value
            ? descriptorVersionOptions
            : [],
      }));

      form.reset(data);
    }
  });

  const missingOption = (
    <Control.Checkbox
      id={buildPrefix('content.options.missing')}
      {...form.register(buildPrefix('content.options.missing'))}
      label={t('tasks:additionalExtraction.форма.content.options.missing.label')}
      errors={form.formState.errors}
      disabled
    />
  );

  const basicAttributesOptions = missingOption;
  const faceDescriptorOptions = (
    <>
      <Control.Select
        id={buildPrefix('content.options.descriptor_version')}
        name={buildPrefix('content.options.descriptor_version')}
        label={t('tasks:additionalExtraction.форма.content.options.descriptor_version.label')}
        control={form.control}
        options={descriptorVersionOptions}
        rules={{
          required: t('валидация.обязательно для заполнения'),
          validate: ({ value }) => validate.intValidator()(value),
        }}
        errors={form.formState.errors}
        isCreatable
        isClearable
      />
      <Divider small />
      {missingOption}
    </>
  );
  const bodyDescriptorOptions = (
    <>
      <Control.Select
        id={buildPrefix('content.options.descriptor_version')}
        name={buildPrefix('content.options.descriptor_version')}
        label={t('tasks:additionalExtraction.форма.content.options.descriptor_version.label')}
        control={form.control}
        rules={{
          required: t('валидация.обязательно для заполнения'),
          validate: ({ value }) => validate.intValidator()(value),
        }}
        errors={form.formState.errors}
        isCreatable
        isClearable
      />
      <Divider small />
      {missingOption}
    </>
  );

  const accountIdFilter = (
    <Control.Input
      id={buildPrefix('content.filters.account_id')}
      label={t('tasks:additionalExtraction.форма.content.filters.account_id.label')}
      {...form.register(buildPrefix('content.filters.account_id'), {
        validate: validate.UUIDValidator(),
      })}
      placeholder={t('tasks:additionalExtraction.форма.content.filters.account_id.placeholder')}
      errors={form.formState.errors}
    />
  );

  const faceFilters = (
    <>
      {accountIdFilter}
      <Divider small />

      <Control.Input
        id={buildPrefix('content.filters.face_id__lt')}
        label={t('tasks:additionalExtraction.форма.content.filters.face_id__lt.label')}
        {...form.register(buildPrefix('content.filters.face_id__lt'), {
          validate: validate.UUIDValidator(),
        })}
        placeholder={t('tasks:additionalExtraction.форма.content.filters.face_id__lt.placeholder')}
        errors={form.formState.errors}
      />
      <Divider small />

      <Control.Input
        id={buildPrefix('content.filters.face_id__gte')}
        label={t('tasks:additionalExtraction.форма.content.filters.face_id__gte.label')}
        {...form.register(buildPrefix('content.filters.face_id__gte'), {
          validate: validate.UUIDValidator(),
        })}
        placeholder={t('tasks:additionalExtraction.форма.content.filters.face_id__gte.placeholder')}
        errors={form.formState.errors}
      />
    </>
  );

  const eventFilters = (
    <>
      {accountIdFilter}
      <Divider small />
      <Control.DateInput
        id={buildPrefix('content.filters.insert_time__lt')}
        name={buildPrefix('content.filters.insert_time__lt')}
        label={t('tasks:additionalExtraction.форма.content.filters.insert_time__lt.label')}
        control={form.control}
        data-testid="insert_time__lt"
        enableTime
      />
      <Divider small />
      <Control.DateInput
        id={buildPrefix('content.filters.insert_time__gte')}
        name={buildPrefix('content.filters.insert_time__gte')}
        label={t('tasks:additionalExtraction.форма.content.filters.insert_time__gte.label')}
        control={form.control}
        data-testid="insert_time__gte"
        enableTime
      />
      <Divider small />
      <Control.Input
        id={buildPrefix('content.filters.handler_id')}
        label={t('tasks:additionalExtraction.форма.content.filters.handler_id.label')}
        {...form.register(buildPrefix('content.filters.handler_id'), {
          validate: validate.UUIDValidator(),
        })}
        placeholder={t('tasks:additionalExtraction.форма.content.filters.handler_id.placeholder')}
        errors={form.formState.errors}
      />
      <Divider small />

      <Control.Input
        id={buildPrefix('content.filters.event_id__lt')}
        label={t('tasks:additionalExtraction.форма.content.filters.event_id__lt.label')}
        {...form.register(buildPrefix('content.filters.event_id__lt'), {
          validate: validate.UUIDValidator(),
        })}
        placeholder={t('tasks:additionalExtraction.форма.content.filters.event_id__lt.placeholder')}
        errors={form.formState.errors}
      />
      <Divider small />

      <Control.Input
        id={buildPrefix('content.filters.event_id__gte')}
        label={t('tasks:additionalExtraction.форма.content.filters.event_id__gte.label')}
        {...form.register(buildPrefix('content.filters.event_id__gte'), {
          validate: validate.UUIDValidator(),
        })}
        placeholder={t('tasks:additionalExtraction.форма.content.filters.event_id__gte.placeholder')}
        errors={form.formState.errors}
      />
    </>
  );

  return (
    <>
      <Control.Select
        id={buildPrefix('content.extraction_target')}
        name={buildPrefix('content.extraction_target')}
        label={t('tasks:additionalExtraction.форма.content.extraction_target.label')}
        control={form.control}
        options={availableExtractionTargetOptions}
      />
      <Divider small />

      <h6>{t('tasks:additionalExtraction.layout.sections.filters')}</h6>

      <Control.Select
        id={buildPrefix('content.filters.objects_type')}
        name={buildPrefix('content.filters.objects_type')}
        label={t('tasks:additionalExtraction.форма.content.filters.objects_type.label')}
        control={form.control}
        options={availableObjectTypes}
      />
      <Divider small />

      {objects_type === 'faces' && faceFilters}
      {objects_type === 'events' && eventFilters}
      <Divider small />

      <h6>{t('tasks:additionalExtraction.layout.sections.options')}</h6>
      {extraction_target === 'basic_attributes' && basicAttributesOptions}
      {extraction_target === 'face_descriptor' && faceDescriptorOptions}
      {extraction_target === 'body_descriptor' && bodyDescriptorOptions}
    </>
  );
}

AdditionalExtractionTargetSubFormComponent.propTypes = {
  prefix: PropTypes.string,
  descriptorVersionOptions: PropTypes.arrayOf(PropTypes.any),
  isLunaEventsAvailable: PropTypes.bool,
};
AdditionalExtractionTargetSubFormComponent.defaultProps = {
  prefix: '',
  isLunaEventsAvailable: false,
  descriptorVersionOptions: [],
};

export const AdditionalExtractionTargetSubForm = connect((state) => ({
  descriptorVersions: selectDescriptorVersionOptions(state),
  LISAvailability: selectLISAvailability(state),
  isLunaEventsAvailable: selectServiceAvailability(state, 'luna-events'),
  descriptorVersionOptions: selectDescriptorVersionOptions(state),
}))(AdditionalExtractionTargetSubFormComponent);
